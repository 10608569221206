import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { UtilService } from '@insureshop/core/services';

@Component({
  selector: 'app-bpi-installment-terms-modal',
  templateUrl: './bpi-installment-terms-modal.component.html',
  styleUrls: ['./bpi-installment-terms-modal.component.scss']
})
export class BpiInstallmentTermsModalComponent implements OnInit {
  public formGroup: FormGroup;
  @Input() next: any;
  @Input() term: any;
  @Input() availableTerms: any;
  @Input() control: FormControl;

  public options: object;

  constructor(public activeModal: NgbActiveModal, private fb: FormBuilder, private util: UtilService) { }

  ngOnInit() {
    //console.log('availableTerms');
    //console.log(this.availableTerms);
    var allterms_array = this.availableTerms.split(',');
    let filteredterms_array = [];
    for(var i = 0; i < allterms_array.length; i++) {
      // Trim the excess whitespace.
      allterms_array[i] = allterms_array[i].replace(/^\s*/, "").replace(/\s*$/, "");
      filteredterms_array.push(this.getBpiEnumObject(allterms_array[i]));
    }
    for(var i = 0; i < allterms_array.length; i++) {
      // Trim the excess whitespace.
      allterms_array[i] = allterms_array[i].replace(/^\s*/, "").replace(/\s*$/, "");
      filteredterms_array.push(this.getBpiBNPLEnumObject(allterms_array[i]));
    }
    //console.log(myArray);
    this.options = filteredterms_array;
    this.buildForm();
  }

  buildForm() {
    this.formGroup = this.fb.group({
      bpiInstallmentTerm: [null, Validators.required],
    });
  }

  get bpiInstallmentTerm() {
    return this.formGroup.get('bpiInstallmentTerm');
  }

  proceed() {
    //console.log(this.formGroup.get('bpiInstallmentTerm').value);
    this.term = this.formGroup.get('bpiInstallmentTerm').value;
    this.activeModal.close(this.term)
  }

  getBpiEnumObject(term: string){
    switch(term) {
      case '3': return { value: 'BPI_INSTALLMENT_3M', label: 'BPI Real 0% Installment (3 Months)' };
      case '6': return { value: 'BPI_INSTALLMENT_6M', label: 'BPI Real 0% Installment (6 Months)' };
      case '9': return { value: 'BPI_INSTALLMENT_9M', label: 'BPI Real 0% Installment (9 Months)' };
      case '12': return { value: 'BPI_INSTALLMENT_12M', label: 'BPI Real 0% Installment (12 Months)' };
      case '18': return { value: 'BPI_INSTALLMENT_18M', label: 'BPI Real 0% Installment (18 Months)' };
      case '24': return { value: 'BPI_INSTALLMENT_24M', label: 'BPI Real 0% Installment (24 Months)' };
      case '36': return { value: 'BPI_INSTALLMENT_36M', label: 'BPI Real 0% Installment (36 Months)' };
   }
  }

  getBpiBNPLEnumObject(term: string){
    switch(term) {
      case '3': return { value: 'BPI_INSTALLMENT_BNPL_3M', label: 'BPI Real 0% Installment (3 Months) with Buy Now, Pay Later' };
      case '6': return { value: 'BPI_INSTALLMENT_BNPL_6M', label: 'BPI Real 0% Installment (6 Months) with Buy Now, Pay Later' };
      case '9': return { value: 'BPI_INSTALLMENT_BNPL_9M', label: 'BPI Real 0% Installment (9 Months) with Buy Now, Pay Later' };
      case '12': return { value: 'BPI_INSTALLMENT_BNPL_12M', label: 'BPI Real 0% Installment (12 Months) with Buy Now, Pay Later' };
      case '18': return { value: 'BPI_INSTALLMENT_BNPL_18M', label: 'BPI Real 0% Installment (18 Months) with Buy Now, Pay Later' };
      case '24': return { value: 'BPI_INSTALLMENT_BNPL_24M', label: 'BPI Real 0% Installment (24 Months) with Buy Now, Pay Later' };
      case '36': return { value: 'BPI_INSTALLMENT_BNPL_36M', label: 'BPI Real 0% Installment (36 Months) with Buy Now, Pay Later' };
   }
  }
}
