import { AbstractControl } from '@angular/forms';

export class ConfirmPasswordValidator {
  static MatchPassword(control: AbstractControl): void {
    const password = control.get('password') || control.get('signupPassword');
    const confirmPassword = control.get('confirmPassword');

    if (
      (password.value !== confirmPassword.value) &&
      (password.value && confirmPassword.value)
    ) {
      confirmPassword.setErrors({ passwordMismatch: true });
    }
  }
}
