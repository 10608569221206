export enum PaymentMethod {
  ONLINE_PAYMENT = 'Debit/Credit Card',
  ONLINE_BANKING = 'Online Banking',
  OTC_NON_BANK = 'Over-The-Counter',
  GCASH = 'GCash',
  BDO_INSTALLMENT = 'BDO Installment Buy Now, Pay Later',
  HSBC_INSTALLMENT = 'HSBC Installment',
  BPI_INSTALLMENT = 'BPI Installment',
  MAYA = 'Maya'
}
