import { Component, OnInit, Input, Output, EventEmitter, OnChanges, ViewChild, TemplateRef } from '@angular/core';
import { PROMO_CODE_FORMAT } from '@insureshop/shared/constants/regex-patterns';
import { FormControl, Validators } from '@angular/forms';
import { InsuranceService, AuthService } from '@insureshop/core/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-promo-code',
  templateUrl: './promo-code.component.html',
  styleUrls: ['./promo-code.component.scss']
})
export class PromoCodeComponent implements OnInit, OnChanges {
  @Input() payload: any;
  @Input() product: string;
  @Output() newPriceBreakdown: EventEmitter<any> = new EventEmitter<any>();
  @Output() applyClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() promoCodeValue: string;
  @ViewChild('confirmModal') confirmModal: TemplateRef<any>;

  public applied: boolean = false;
  public promoInput: FormControl = new FormControl(null, Validators.pattern(PROMO_CODE_FORMAT));
  public serverErrorMsg: string;
  public validating: boolean = false;
  public disableRemove: boolean = false;
  public forceDisable: boolean = false;
  public hidePromo: boolean = false;
  public employeeName: string;

  constructor(
    private is: InsuranceService,
    private modalService: NgbModal,
    private authService: AuthService
  ) {}

  ngOnChanges() {
    this.promoInput.patchValue(this.payload.promoCode ? this.payload.promoCode :
      this.authService.getSessionItem('agent_code') ? this.authService.getSessionItem('promo_code') :
        this.authService.getLocalizeItem('agent_code') ? this.authService.getLocalizeItem('promo_code') :'');
    this.applied = this.payload.promoCode ? true :
      this.authService.getSessionItem('agent_code') ? true :
        this.authService.getLocalizeItem('agent_code') ? true : false;
    if(this.promoCodeValue) {
      this.promoInput.patchValue(this.promoCodeValue);
    }

    if(this.authService.getSessionItem('product_name') && this.authService.getSessionItem('promo_code')){
      if(this.authService.getSessionItem('promo_code').includes('UNTTC')
      && (this.authService.getSessionItem('product_name') !== 'SafeTrip')){
        this.promoInput.patchValue(null);
        this.applied = false;
        this.forceDisable = true;
      }
      if(this.authService.getSessionItem('promo_code').includes('EMP')){
        this.hidePromo = true;
        this.employeeName =  this.authService.getSessionItem('first_name') + ' ' + this.authService.getSessionItem('last_name');
      }
    }
  }

  ngOnInit() {
    this.promoInput.patchValue(this.payload.promoCode ? this.payload.promoCode :
      this.authService.getSessionItem('agent_code') ? this.authService.getSessionItem('promo_code') :
      this.authService.getLocalizeItem('agent_code') ? this.authService.getLocalizeItem('promo_code') : null);
    this.applied = this.payload.promoCode ? true :
      this.authService.getSessionItem('agent_code') ? true :
        this.authService.getLocalizeItem('agent_code') ? true : false;
    this.promoInput.valueChanges.subscribe(value => this.is.setPromoCode(value));
    if (this.authService.getSessionItem('agent_code') || this.authService.getLocalizeItem('agent_code')) {
      this.disableRemove = true;
    }

    if(this.authService.getSessionItem('product_name') && this.authService.getSessionItem('promo_code')){
      if(this.authService.getSessionItem('promo_code').includes('UNTTC')
      && (this.authService.getSessionItem('product_name') !== 'SafeTrip')){
        this.promoInput.patchValue(null);
        this.applied = false;
        this.forceDisable = true;
      }
      if(this.authService.getSessionItem('promo_code').includes('EMP')){
        this.hidePromo = true;
        this.employeeName =  this.authService.getSessionItem('first_name') + ' ' + this.authService.getSessionItem('last_name');
      }
    }
  }

  valueChange(event: any) {
    this.is.setPromoCode(event.target.value);
  }

  async applyPromo() {
    this.validating = true;

    if (this.isEmpty() && !this.is.getPromoCode()) {
      this.validatePromoCode();
      this.validating = false;
    } else {
      if (this.promoInput.valid) {
        if (!this.promoInput.value) {
          this.promoInput.setValue(this.is.getPromoCode());
        }
        const data = Object.assign(this.payload.data, {
          ...this.payload.data,
          promoCode: this.promoInput.value
        });
        this.is.applyPromoCode(data, this.product).subscribe(res => {
          this.newPriceBreakdown.emit(res);
          this.applied = true;
          this.validating = false;
        }, err => {
          this.validatePromoCode(true);
          this.serverErrorMsg = err.error.message.split(':')[1];
          this.validating = false;
          this.applyClicked.emit(false);
        });
      } else {
        this.validatePromoCode();
        this.validating = false;
        this.applyClicked.emit(false);
      }
    }
  }

  isEmpty() {
    return this.promoInput.value === '' || !this.promoInput.value ? true : false;
  }

  openModal(content: any) {
    this.modalService.open(content)
  }

  removePromoConfirm() {
    const data = Object.assign(this.payload.data, { ...this.payload.data });
    this.is.applyPromoCode(data, this.product).subscribe(res => {
      Object.assign(res, { ...res, promoCode: undefined, discount: undefined })
      this.promoInput.setValue(null);
      this.promoInput.reset();
      this.newPriceBreakdown.emit(res);
      this.applied = false;
      this.modalService.dismissAll();
    });
  }

  validatePromoCode(serverError?: boolean) {
    if (serverError) {
      this.promoInput.setErrors({ serverError: true });
    } else {
      this.promoInput.markAsTouched();
      this.promoInput.setErrors({ invalidCode: true });
    }
  }
}
