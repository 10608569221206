import { Name } from '@insureshop/shared/models/name.model';

export class MotorExistingCustomer {
    constructor(
        public name: Name,
        public emailAddress: string,
        public mobileNumber: string,
        public motorBrand: string,
        public motorYear: string,
        public motorModel: string,
        public engineNumber: string,
        public chassisNumber: string
    ) {}
}
