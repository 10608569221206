import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InsuranceService } from '@insureshop/core/services';

@Component({
  selector: 'app-existing-customer',
  templateUrl: './existing-customer.component.html',
  styleUrls: ['./existing-customer.component.scss']
})
export class ExistingCustomerComponent implements OnInit {
  @Input() id: number;

  constructor(
    private router: Router,
    public activeModal: NgbActiveModal,
    private insuranceService: InsuranceService
  ) {}

  ngOnInit() {}

  goToHomepage() {
    this.activeModal.close();
    this.router.navigateByUrl('/');
  }
}
