import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { CoreServices } from '@insureshop/core/services';
import { TokenInterceptor } from '@insureshop/core/services/token.interceptor';
import { DataTableUtil } from '@insureshop/shared/utils/data-table.util';
import { EntryComponents } from '@insureshop/shared/components/modals';
import { SharedModule } from '@insureshop/shared/shared.module';

@NgModule({
  imports: [
    HttpClientModule,
    SharedModule
  ],
  providers: [
    DataTableUtil,
    ...CoreServices,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
  ],
  declarations: [],
  entryComponents: [
    ...EntryComponents
  ]
})
export class CoreModule { }
