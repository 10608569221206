import { Name } from '@insureshop/shared/models/name.model';
import { Payor } from '@insureshop/shared/models/payor.model';
import { PriceBreakdown } from '@insureshop/shared/models/price-breakdown.model';
import { Product } from '@insureshop/shared/models/product.model';

export class TravelWizard {
  constructor(
    public prelim?: {
      destinations: {
        value: number,
        display: string
      }[],
      destinationType: string,
      destinationIds: number[],
      destinationNames: string[]
      destinationIATA: string[]
      days: number,
      date: any[]
      travelCompanion: string,
      questionNumber?: number,
      backToReviewPage?: boolean
    },
    public product?: Product,
    public payor?: Payor,
    public policyHolders?: {
      name: Name,
      gender: string,
      birthDate: Date,
      idType: string,
      idNumber: string,
      emailAddress: string,
      mobileNumber: number,
      familyRole: string
    }[],
    public sameAddress?: boolean[],
    public travelPurpose?: string,
    public channelSource?: string,
    public paid?: boolean,
    public thisIsMe?: boolean,
    public total?: number,
    public payorValid?: boolean,
    public insuredValid?: boolean,
    public policyHolderCount?: number,
    public priceDetails?: PriceBreakdown,
    public others?: {
      priceDetails: PriceBreakdown,
      total: number,
      policyHoldersCount: number
    },
    public dst?: number,
    public lgt?: number,
    public premiumTax?: number,
    public enabledTabs?: boolean[],
    public promoCode?: string,
    public discount?: number
  ) {}
}
