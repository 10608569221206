import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { en_US, NzI18nService, UploadFile } from 'ng-zorro-antd';

@Component({
  selector: 'app-upload-invoice',
  templateUrl: './upload-invoice.component.html',
  styleUrls: ['./upload-invoice.component.scss']
})
export class UploadInvoiceComponent implements OnInit {
  @Input() control: FormControl;

  // add limit for file upload
  @Input() fileLimit: number;

  @Input() showUploadList: boolean;

  public selectedFile: File[] = [];
  public fileTypes = [
    'image/jpeg',
    'image/png',
    'image/gif',
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ];

  constructor(private i18n: NzI18nService) { }

  ngOnInit() {
    // set default
    if(typeof this.fileLimit === 'undefined') {
      this.fileLimit = 3145728; //3mb
    }

    if(typeof this.showUploadList === 'undefined') {
      this.showUploadList = false;
    }

    window.scroll(0, 0);

    this.i18n.setLocale(en_US);

    this.selectedFile = this.control.value || [];
  }

  beforeUpload = (file: File): boolean => {
    this.selectedFile = [];

    if (this.fileTypes.indexOf(file.type) > -1 && file.size <= this.fileLimit) {
      this.selectedFile = this.selectedFile.concat(file);
      this.control.setValue([...this.selectedFile]);
    } else {
      this.control.setErrors({'invalidFile': true});
    }
    return false;
  };

  removeFile = (file: UploadFile): boolean => {
    // set empty array since only 1 is allowed
    this.control.setValue([]);

    return true;
  }
}
