import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable, Subject, Subscription, fromEvent, merge, timer } from 'rxjs';
import { debounceTime, finalize, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private idle: Observable<any> = new Observable();
  private timer: Subscription = new Subscription();
  private timeOutMilliSeconds: number = 1000;
  private idleSubscription: Subscription = new Subscription();
  public expired: Subject<number> = new Subject<number>();
  public watchTrigger: Subject<boolean> = new Subject<boolean>();
  public initialEmitCompleted: boolean = false;

  public startWatching(timeOutSeconds: number): Observable<any> {
    this.idle = merge(
      fromEvent(document, 'mousemove'),
      fromEvent(document, 'click'),
      fromEvent(document, 'mousedown'),
      fromEvent(document, 'keypress'),
      fromEvent(document, 'DOMMouseScroll'),
      fromEvent(document, 'mousewheel'),
      fromEvent(document, 'touchmove'),
      fromEvent(document, 'MSPointerMove'),
      fromEvent(window, 'mousemove'),
      fromEvent(window, 'resize'),
    );
    this.timeOutMilliSeconds = timeOutSeconds * 1000;
    this.idleSubscription = this.idle
      .pipe(
        debounceTime(500)
      )
      .subscribe(() => {
        this.resetTimer();
      });
    this.startTimer()
    return this.expired;
  }

  public startTimer() {
    let remainingTime = this.timeOutMilliSeconds / 1000;
    this.timer = timer(0, 1000).pipe(
      take(remainingTime),
      tap(() => {
        if(remainingTime === (environment.sessionTimeoutReminderInMinutes * 60)) { // limit the values to emit
          this.expired.next(remainingTime); // only emit necessry values
        }
        remainingTime--;
      }),
      finalize(() => {
        if (remainingTime === 0) {
          this.expired.next(0);
        }
      })
    ).subscribe();
  }

  public resetTimer() {
    this.timer.unsubscribe();
    this.startTimer();
  }

  public stopTimer() {
    this.timer.unsubscribe();
    this.idleSubscription.unsubscribe();
  }

  public triggerWatch() {
    if(!this.initialEmitCompleted) { // prevents triggering after inital emit
      console.log('DID EMIT')
      this.watchTrigger.next(true);
      this.initialEmitCompleted = true;
    }
  }
}
