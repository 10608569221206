import { NgModule } from '@angular/core';
import { MbscDatetimeModule, MbscRangeModule, MbscCalendarModule } from '@mobiscroll/angular';

@NgModule({
  imports: [
    MbscDatetimeModule,
    MbscRangeModule,
    MbscCalendarModule
  ],
  exports: [
    MbscDatetimeModule,
    MbscRangeModule,
    MbscCalendarModule
  ],
})
export class MobiscrollModule { }
