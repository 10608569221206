export class EPayment {
  constructor(
    public host?: string,
    public merchantCode?: string,
    public paymentId?: number,
    public referenceNumber?: string,
    public refNo?: string,
    public amount?: any,
    public currency?: string,
    public productDescription?: string,
    public prodDesc?: string,
    public name?: string,
    public userName?: string,
    public emailAddress?: string,
    public userEmail?: string,
    public mobileNumber?: string,
    public userContact?: string,
    public remarks?: string,
    public remark?: string,
    public encoding?: string,
    public lang?: string,
    public signature?: string,
    public successRedirectURL?: string,
    public responseURL?: string,
    public backendURL?: string,
    public successPostURL?: string,
    public failURL?: string,
    public successURL?: string,
    public paymentOptions?: string,
    public cancelURL?: string,
    public fredirect?: string,
    public installmentEnabled?: boolean,
    public SC_MC?: string,
    public SC_AMOUNT?: string,
    public SC_REF?: string,
    public SC_SUCCESSURL?: string,
    public SC_FAILURL?: string,
    public SC_CANCELURL?: string,
    public SC_OPTIONS?: string,
    public SC_FREDIRECT?: string
  ) {}
}
