import { Injectable } from '@angular/core';

declare let fbq: Function;

@Injectable({
  providedIn: 'root'
})
export class PixelService {
  constructor() {}

  public eventEmitter(tracker: string, eventName: string, extras?: any) {
    fbq(tracker, eventName, extras);
  }
}
