export enum Suffix {
  JR = 'Jr',
  SR = 'Sr',
  II = 'II',
  III = 'III',
  IV = 'IV',
  V = 'V',
  VI = 'VI',
  VII = 'VII',
  VIII = 'VIII',
  IX = 'IX'
}
