import { Name } from '@insureshop/shared/models/name.model';
import { Address } from '@insureshop/shared/models/address.model';
import { OTP } from './otp.model';
import { DateUtil } from '../utils/date.util';

export class User {
  constructor(
    public id?: string | number,
    public name?: Name,
    public emailAddress?: string,
    public mobileNumber?: string,
    public authorities?: string[],
    public birthDate?: Date,
    public maritalStatus?: string,
    public gender?: string,
    public address?: Address,
    public otp?: OTP,
    public otpVerified?: boolean
  ) {}

  static serializeUser(data: Object): User {
    return new User(
      data['id'],
      data['name'],
      data['emailAddress'],
      data['mobileNumber'],
      data['authorities'],
      data['birthDate'] ? DateUtil.extractDateFromDateTime(data['birthDate']) : data['birthDate'],
      data['maritalStatus'],
      data['gender'],
      data['address'],
      data['otp'],
      data['otpVerified']
    );
  }
}
