import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class ConsoleLoggerService {

  constructor() { }

  disableConsoleLogsProd() {
    // disable logs in prod env
    if(environment.production) {
      console.log = function(): void {};
    }
  }
}
