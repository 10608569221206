import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '@env/environment';
import { AuthService } from '@insureshop/core/services';
import { LoginComponent } from '@insureshop/shared/components/modals/login/login.component';
import { User } from '@insureshop/shared/models/user.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-secure-layout',
  templateUrl: './public-layout.component.html',
  styleUrls: ['./public-layout.component.scss']
})
export class PublicLayoutComponent {
  hospicashLink: string;
  ooopsieLink: string;
  ctplLink: string;
  breadcrumbData: any;

  constructor(
    private authService: AuthService,
    private modalService: NgbModal,
    private router: Router) {}

  ngOnInit() {
    if (this.authService.getSessionItem('hash_key')) {
      this.hospicashLink = environment.accihealthConfig.hospicash + '?agentcode=' + this.authService.getSessionItem('hash_key');
      this.ooopsieLink = environment.accihealthConfig.ooopsie + '?agentcode=' + this.authService.getSessionItem('hash_key');
      this.ctplLink = environment.ctplConfig.ctpl + '?agentcode=' + this.authService.getSessionItem('hash_key');
    } else if (this.authService.getLocalizeItem('hash_key')) {
      this.hospicashLink = environment.accihealthConfig.hospicash + '?agentcode=' + this.authService.getLocalizeItem('hash_key');
      this.ooopsieLink = environment.accihealthConfig.ooopsie + '?agentcode=' + this.authService.getLocalizeItem('hash_key');
      this.ctplLink = environment.ctplConfig.ctpl + '?agentcode=' + this.authService.getLocalizeItem('hash_key');
    } else {
      this.hospicashLink = environment.accihealthConfig.hospicash;
      this.ooopsieLink = environment.accihealthConfig.ooopsie;
      this.ctplLink = environment.ctplConfig.ctpl;
    }

    //initial load
    const currentRoute = this.router.url;
    this.breadcrumbData = this.getBreadcrumbData(currentRoute);
    // NEED TO ADD FUNCTION FOR SPLITTING ROUTE AND QUERYPARAM

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      // set as empty to trigger change
      this.breadcrumbData = [];
      setTimeout(() => {
        this.breadcrumbData = this.getBreadcrumbData(event.url);
      }, 10);
    });
  }

  // gets data for breadcrumb UI
  getBreadcrumbData(route: string) {
    const matchingConfig = environment.breadcrumbConfig
      .find(data => data.route.toLowerCase() === route.toLowerCase());
    return matchingConfig ? matchingConfig.stack : undefined;
  }

  redirectToHospicash() {
    if (this.authService.getSessionItem('hash_key')) {
      window.location.href =  environment.accihealthConfig.hospicash + '?agentcode=' + this.authService.getSessionItem('hash_key');
    } else if (this.authService.getLocalizeItem('hash_key')) {
      window.location.href = environment.accihealthConfig.hospicash + '?agentcode=' + this.authService.getLocalizeItem('hash_key');
    } else {
      window.location.href = environment.accihealthConfig.hospicash;
    }
  }
  redirectToOoopsie() {
    if (this.authService.getSessionItem('hash_key')) {
      window.location.href =  environment.accihealthConfig.ooopsie + '?agentcode=' + this.authService.getSessionItem('hash_key');
    } else if (this.authService.getLocalizeItem('hash_key')) {
      window.location.href = environment.accihealthConfig.ooopsie + '?agentcode=' + this.authService.getLocalizeItem('hash_key');
    } else {
      window.location.href = environment.accihealthConfig.ooopsie;
    }
  }
  redirectToCtpl() {
    if (this.authService.getSessionItem('hash_key')) {
      window.location.href =  environment.ctplConfig.ctpl + '?agentcode=' + this.authService.getSessionItem('hash_key');
    } else if (this.authService.getLocalizeItem('hash_key')) {
      window.location.href = environment.ctplConfig.ctpl + '?agentcode=' + this.authService.getLocalizeItem('hash_key');
    } else {
      window.location.href = environment.ctplConfig.ctpl;
    }
  }

  public currentUser(): Observable<User> {
    return this.authService.getCurrentUser();
  }

  public login() {
    this.modalService.open(LoginComponent, { size: 'lg' });
  }

  public logout(): void {
    this.authService.logout().subscribe(
      () => true,
      () => {
        this.authService.$userState.next(null);
        this.authService.removeToken();
        this.authService.removeKeycloakId();
      }
    );
  }

  goToDayTripSureTrip() {
    window.location.href = environment.sureDayTripConfig.url;
  }

  goToPetInsurance() {
    window.location.href = environment.petConfig.url;
  }

  goToOFWInsurance() {
    window.location.href = environment.ofwConfig.url;
  }

  goToHomE() {
    window.location.href = environment.homeConfig.url;
  }

  goToBuhayAtAksidente() {
    window.location.href = environment.buhayConfig.aksidente;
  }

  goToBuhayAtBahay() {
    window.location.href = environment.buhayConfig.bahay;
  }

  goToBuhayAtOspital() {
    window.location.href = environment.buhayConfig.ospital;
  }
}
