import { Address } from '@insureshop/shared/models/address.model';
import { Name } from '@insureshop/shared/models/name.model';
import { Payor } from '@insureshop/shared/models/payor.model';
import { PriceBreakdown } from '@insureshop/shared/models/price-breakdown.model';

export class MotorWizard {
  // Prelim
  brand: number | string;
  year: number | string;
  motorModelId: number;
  brandName: string;
  yearDescription: string;
  motorModelCodeDesc: string;
  motorSrp: number;
  motorPurchasePrice: number;
  ageRange: string;
  drivingExperience: string;
  usageType: string;
  purchaseType: string;
  modelAndCode: string;

  // Coverage
  theftPercentCoverage: number;
  actsOfNaturePercentCoverage: number;
  tpbi: boolean;
  tppd: boolean;
  personalAccident: boolean;
  personalAccidentBenefitAmount: number;
  ownDamageTheftPercentCoverage: number;

  priceDetails: PriceBreakdown;
  total: number;

  // Personal Info
  payor: Payor;
  payorValid: boolean;

  policyHolderIsPayor: boolean;

  // Driver and Policy Holder
  policyHolder: {
    name: Name;
    gender: string;
    maritalStatus: string;
    birthDate: Date;
    address: Address;
    emailAddress: string;
    mobileNumber: number;
  };

  policyHolderIsDriver: boolean;

  driver: {
    name: Name;
    gender: string;
    maritalStatus: string;
    birthDate: Date;
    licenseNumber: string;
  };

  chassisNumber: string;
  engineNumber: string;
  plateNumber: string;

  mortgagee: string;

  attachments: any[];
  motorInsuranceImages: any[];
  invoice: any[];

  id: number;

  enabledTabs: boolean[];

  promoCode?: string;
  discount?: number
}
