import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { UtilService } from '@insureshop/core/services';
import { ReferenceService } from '@insureshop/core/services';
import { Suffix } from '@insureshop/modules/insurance/enums';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-name',
  templateUrl: './name.component.html',
  styleUrls: ['./name.component.scss']
})
export class NameComponent implements OnInit, OnDestroy {
  @Input() formGroup: FormGroup;
  @Input() submitted: boolean;
  @Input() parentName: string; // for custom implementation
  @Input() required: boolean = true;
  public options: any;

  constructor(
    private util: UtilService,
    private fb: FormBuilder,
    private referenceService: ReferenceService
    ) {}

  ngOnInit() {

    if(this.parentName && this.parentName == "motorCar") {
      // fetch suffix LOVs
      this.referenceService.getSuffix()
      .pipe(
        untilComponentDestroyed(this),
        catchError(this.errorHandler())
      )
      .subscribe((items) => {
        this.options = items.resultData;
      });
    } else {
      this.options = this.util.enumToArray(Suffix);
    }
  }

  ngOnDestroy() {}

  get firstName() { return this.formGroup.get('firstName'); }
  get middleName() { return this.formGroup.get('middleName'); }
  get lastName() { return this.formGroup.get('lastName'); }
  get suffix() { return this.formGroup.get('suffix'); }

  /**
   * @description error handler for requests
   *
   */
  errorHandler() {
    return (error: any) => {
      console.error('Error:', error);
      // return an empty array
      return of([]);
    };
  }
}
