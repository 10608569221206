import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ReferenceService } from '@insureshop/core/services';
import { NgOption } from '@ng-select/ng-select';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {
  @Input() set formGroup(address: FormGroup) {
    this._address = address;
    if (this.province.value) {
      this.getCities(this.province.value, false);
    }
  }

  @Input() required = true;
  @Input() submitted: boolean;

  private _address: FormGroup;
  public provinces: NgOption[];
  public cities: NgOption[];

  constructor(private referenceService: ReferenceService) {}

  ngOnInit() {
    this.getProvinces();
  }

  getProvinces() {
    this.referenceService.getProvinces().subscribe((provinces: NgOption[]) => {
      this.provinces = this.sortProvinces(provinces);
      if(this.province.value && !this.province.value.id) {
        this.province.setValue(this.provinces.find(p => p.name === this.province.value.name));
        this.getCities(this.province.value, false);
      }
    });
  }

  getCities(province: NgOption, reset = true) {
    this.cities = null;
    if(province) {
      this.referenceService.getCities(province.id).subscribe((cities: NgOption[]) => {
        this.cities = cities;
        // Reset City and Barangay
        if (reset) {
          this.city.patchValue(null);
        } else {
          var arrayWithIds = this.cities.map(function(x){
              return x.id
            });
          if (arrayWithIds.indexOf(JSON.parse(JSON.stringify(this.city.value))['id']) == -1) {
            this.city.patchValue(null);
          }
        }
      });
    }
  }

  sortProvinces(provinces: any) {
    let sortedProvinces = provinces.sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    return sortedProvinces;
  }

  get address() { return this._address; }
  get city() { return this.address.get('city'); }
  get province() { return this.address.get('province'); }
  get barangay() { return this.address.get('barangay'); }
  get street() { return this.address.get('street'); }
}
