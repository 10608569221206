import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpXsrfTokenExtractor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from './config.service';

@Injectable()
export class HttpXsrfInterceptor implements HttpInterceptor {
  constructor(private tokenExtractor: HttpXsrfTokenExtractor, private configService: ConfigService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headerName = 'XSRF-TOKEN';
    const respHeaderName = 'X-XSRF-TOKEN';
    let token = this.tokenExtractor.getToken() as string;
    // Exclude AcciHealth, CTPL, and Alfresco urls to avoid CORS issues
    console.log('request url');
    console.log(req.url)
    if (
      token !== null &&
      !req.headers.has(headerName) &&
      (!req.url.includes(this.configService.getAccihealthConfig().url) &&
      !req.url.includes(this.configService.getCtplConfig().url) &&
      !req.url.includes('alfresco') && !req.url.includes(this.configService.getMotorCompreConfig().url)) &&
      !req.url.includes('maxxpayment')
    ) {
      req = req.clone({ headers: req.headers.set(respHeaderName, token) });
    }
    if(req.url.includes('maxxpayment')){
      req = req.clone({ headers: req.headers.delete('Authorization') });
    }
    console.log('headers');
    console.log(req.headers);
    return next.handle(req);
  }
}
