import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService, PixelService, UtilService, AesService } from '@insureshop/core/services';
import { CONTACT_NUMBER_FORMAT, EMAIL_FORMAT, PASSWORD } from '@insureshop/shared/constants/regex-patterns';
import { OTP, Generic } from '@insureshop/shared/models';
import { ConfirmPasswordValidator } from '@insureshop/shared/validators/confirm-password.validator';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @Input() purchaseProcess: string;

  public loginForm: FormGroup;
  public signupForm: FormGroup;
  public forgotPasswordForm: FormGroup;

  public showInitialModal: boolean = true;
  public showLogin: boolean = true;

  public showSignupModal: boolean = false;
  public signingUp: boolean = false;
  public signupSuccess: boolean = false;
  public signupError: string = '';

  public showForgotPassword: boolean = false;
  public showForgotPasswordSuccess: boolean = false;
  public forgotPasswordEmailSending: boolean = false;
  public forgotPasswordError: string = '';
  public showLoginPassword: boolean = false;
  public showSignupPassword: boolean = false;
  public showSignupPassConf: boolean = false;

  public otp = new FormControl(null);
  public otpInfo: OTP;
  public showOtpInput: boolean = false;
  public sendingOtp: boolean = false;
  public verifyingOtp: boolean = false;
  public otpVerified: boolean = false;
  public otpLimit: boolean = false;
  public accountBlocked = false;
  public invalidCredentials = false;

  public gen: Generic;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private utilService: UtilService,
    private pxSvc: PixelService,
    public activeModal: NgbActiveModal,
    public aes: AesService
  ) {}

  ngOnInit() {
    this.loginForm = this.fb.group({
      username: [null, [Validators.required, Validators.pattern(EMAIL_FORMAT), Validators.maxLength(50)]],
      password: [null, Validators.required]
    });

    this.signupForm = this.fb.group({
      email: [null, [Validators.required, Validators.pattern(EMAIL_FORMAT), Validators.maxLength(50)]],
      mobile: [null, [Validators.required, Validators.pattern(CONTACT_NUMBER_FORMAT)]],
      signupPassword: [null, [Validators.required, Validators.pattern(PASSWORD)]],
      confirmPassword: [null, [Validators.required, Validators.pattern(PASSWORD)]]
    }, {
      validator: ConfirmPasswordValidator.MatchPassword
    });

    this.forgotPasswordForm = this.fb.group({
      forgotPasswordEmail: [null, [Validators.required, Validators.pattern(EMAIL_FORMAT), Validators.maxLength(50)]]
    });
  }

  purchaseProcessSelected(product) {
    let returnProduct = '';
    switch(product) {
      case 'travel':
        returnProduct = 'purchaseProcessTravel';
        break;

      case 'health':
          returnProduct = 'purchaseProcessHealth';
          break;

      case 'motor':
          returnProduct = 'purchaseProcessMotor';
          break;

      default:
        returnProduct = 'userLogin'
    }

    return returnProduct;
  }

  login(loginDetails: any): void {
    this.accountBlocked = false;
    this.invalidCredentials = false;
    this.loginForm.setErrors(null);

    this.aes.generateAdminKey().subscribe((gen: Generic) => {
      this.gen = this.aes.encryptJson(loginDetails, gen);
      this.authService.login2(this.gen).subscribe(() => {
        this.activeModal.close();
      }, e => {
        this.loginForm.get('username').setErrors({ invalid: true });
        this.loginForm.get('password').setErrors({ invalid: true });

        if (e.status === 400 && e.error['error_description'].includes('disabled')) {
          this.accountBlocked = true;
        } else {
          this.invalidCredentials = true;
        }
      });
      this.pxSvc.eventEmitter('track', 'Lead');
    });
  }

  validateSignup() {
    this.signupError = '';
    this.aes.generateAdminKey().subscribe((gen: Generic) => {
      this.gen = this.aes.encryptString(this.email.value, gen);
      this.authService.checkUsername2(this.gen).subscribe(response => {
        if (!response.exists) {
          this.sendOtp();
        } else {
          this.signupError = 'Email address is already registered. Would you like to log-in?'
        }
      }, () => {
      });
      /*this.authService.checkUsername(this.email.value).subscribe(response => {
        if (!response.exists) {
          this.sendOtp();
        } else {
          this.signupError = 'Email address is already registered. Would you like to log-in?'
        }
      }, () => {
      });*/
    });
  }

  sendOtp(): void {
    this.sendingOtp = true;
    this.signupError = '';
    this.otpVerified = false;
    let contactDetails = new OTP(null,  this.signupForm.value.mobile, this.signupForm.value.email, 0);
    this.aes.generateAdminKey().subscribe((gen: Generic) => {
      if(contactDetails.mobileNumber !== null){
        contactDetails.mobileNumber = '63' + contactDetails.mobileNumber;
      }
      this.gen = this.aes.encryptJson(contactDetails, gen);
      this.utilService.sendOtp2(this.gen).subscribe((otp: OTP) => {
        this.otpInfo = otp;
        this.sendingOtp = false;
        this.showOtpInput = true;
        this.otpLimit = false;
        if (otp.requestCount!=null && otp.requestCount >= 5) {
          this.otpLimit = true;
        }
      }, () => {
        this.sendingOtp = false;
      });
    });

  }

  verifyOtp() {
    this.verifyingOtp = true;
    this.utilService.verifyOtp(this.otpInfo.id, this.otp.value).subscribe(response => {
      this.verifyingOtp = false;
      this.otpVerified = response.verified;
      if (response.verified) {
        let signupDetails = {
          emailAddress : this.signupForm.value.email,
          mobileNumber : this.signupForm.value.mobile,
          password : this.signupForm.value.signupPassword
        };
        this.signingUp = true;

        this.aes.generateAdminKey().subscribe((gen: Generic) => {
          this.gen = this.aes.encryptJson(signupDetails, gen);
          this.authService.signup2(this.gen).subscribe(() => {
            this.signingUp = false;
            this.signupSuccess = true;
          }, errorRes => {
            this.showOtpInput = false;
            this.signingUp = false;
            this.signupError = errorRes.error.errorMessage;
          });
        });
      } else {
        this.otp.setErrors({invalid: true});
      }

      this.pxSvc.eventEmitter('track', 'Lead');
    }, () => {
      this.verifyingOtp = false;
    });
  }

  continueLogin() {
    let newLoginDetails = {
      username: this.signupForm.value.email,
      password: this.signupForm.value.signupPassword
    };
    this.login(newLoginDetails);
  }

  validateForgotPassword() {
    this.forgotPasswordError = '';
    this.forgotPasswordEmailSending = true;
    this.aes.generateAdminKey().subscribe((gen: Generic) => {
      this.gen = this.aes.encryptString(this.forgotPasswordEmail.value, gen);
      this.authService.checkUsername2(this.gen).subscribe(response => {
        if (response.exists) {
          this.forgotPassword();
        } else {
          this.forgotPasswordError = 'User with email address provided does not exist.';
          this.forgotPasswordEmailSending = false;
        }
      }, () => {
      });
      /*
      this.authService.checkUsername(this.forgotPasswordEmail.value).subscribe(response => {
        if (response.exists) {
          this.forgotPassword();
        } else {
          this.forgotPasswordError = 'User with email address provided does not exist.';
          this.forgotPasswordEmailSending = false;
        }
      }, () => {
      });*/
    });
  }

  forgotPassword() {
    this.aes.generateAdminKey().subscribe((gen: Generic) => {
      this.gen = this.aes.encryptString(this.forgotPasswordEmail.value, gen);
      this.authService.forgotPassword2(this.gen).subscribe(() => {
        this.forgotPasswordEmailSending = false;
        this.showForgotPassword = false;
        this.showForgotPasswordSuccess = true;
      }, () => {
      });
    });
  }

  toggleForgetPassword() {
    this.showLogin = false;
    this.showForgotPassword = true;
  }

  toggleLogin() {
    this.showForgotPasswordSuccess = false;
    this.showForgotPassword = false;
    this.showLogin = true;
  }

  toggleSignup() {
    this.showInitialModal = false
    this.showSignupModal = true;
  }

  toggleInitialModal() {
    this.showSignupModal = false;
    this.showInitialModal = true;
    this.toggleLogin();
  }

  togglePasswordMask(passwordRef: string) {
    switch(passwordRef) {
      case 'loginPassword':
        this.showLoginPassword = !this.showLoginPassword;
        break;

      case 'signupPassword':
        this.showSignupPassword = !this.showSignupPassword;
        break;

      default:
        this.showSignupPassConf = !this.showSignupPassConf;
    }
  }

  get username() { return this.loginForm.get('username') }
  get password() { return this.loginForm.get('password') }
  get email() { return this.signupForm.get('email') }
  get mobile() { return this.signupForm.get('mobile') }
  get signupPassword() { return this.signupForm.get('signupPassword') }
  get confirmPassword() { return this.signupForm.get('confirmPassword') }
  get forgotPasswordEmail() { return this.forgotPasswordForm.get('forgotPasswordEmail') }
}
