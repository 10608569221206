import { Name } from '@insureshop/shared/models/name.model';
import { Payor } from '@insureshop/shared/models/payor.model';

export class TravelInsurance {
  constructor(
    public payor: Payor,
    public policyHolders: {
      name: Name,
      gender: string,
      birthDate: Date,
      idType: string,
      idNumber: string,
      emailAddress: string,
      mobileNumber: number
    } [],
    public productVarianceId: number,
    public productVarianceName: string,
    public productName: string,
    public policyType: string,
    public destinations: string[],
    public iata: string[],
    public days: number,
    public travelCompanion: string,
    public startDate: Date,
    public endDate: Date,
    public travelPurpose: string,
    public channelSource: string,
    public id?: number,
    public promoCode?: string,
    public discount?: number
  ) {}
}
