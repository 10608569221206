export class PriceBreakdown {
  constructor(
    public dst: number,
    public lgt: number,
    public premium: number,
    public premiumTax: number,
    public price: number,
    public qty: number,
    public description: string,
    public promoCode?: string,
    public discount?: number
  ) {}
}
