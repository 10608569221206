export class RateCard {
  ownDamage: number;
  theft: number;
  // actOfNature: {
  //   code: number;
  //   description: number;
  // } [];
  purchaseSlider: {
    code: number;
    description: number;
  } [];
  tppd: number;
  tpbi: number;
  personalAccident: number;
}
