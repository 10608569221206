import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CoreModule } from '@insureshop/core/core.module';
import { PageNotFoundComponent } from '@insureshop/shared/components/404/page-not-found.component';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';
import { CookieConfig } from '../assets/config/cookie-config';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SessionSharingComponent } from './shared/components/session-sharing/session-sharing.component';
import { AppConfigProvider } from './shared/providers/app-config.provider';
import { HttpClientModule, HttpClientXsrfModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpXsrfInterceptor } from '@insureshop/core/services/cookie.interceptor';

import { RECAPTCHA_SETTINGS,  RecaptchaSettings } from 'ng-recaptcha';

import { environment } from '@env/environment';

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    SessionSharingComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    CoreModule,
    RouterModule,
    NgcCookieConsentModule.forRoot(CookieConfig),
    AppRoutingModule,
    MatTableModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    HttpClientModule,
    HttpClientXsrfModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (appConfig: AppConfigProvider) => { return () => appConfig.loadConfig() },
      deps: [AppConfigProvider], multi: true
    },
    {
      provide: 'EXTERNAL_SESSION_URL',
      useFactory: (appConfig: AppConfigProvider) => { return appConfig.getConfig()['external_session_url'] },
      deps: [AppConfigProvider]
    },
    { provide: HTTP_INTERCEPTORS, useClass: HttpXsrfInterceptor, multi: true },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptchaSiteKey,
      } as RecaptchaSettings,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
