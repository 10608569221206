import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UtilService } from '@insureshop/core/services';
import { MaritalStatus } from '@insureshop/modules/insurance/enums';
import { UpdatedMaritalStatus } from '@insureshop/modules/insurance/enums';

@Component({
  selector: 'app-marital-status-selection',
  templateUrl: './marital-status-selection.component.html',
  styleUrls: ['./marital-status-selection.component.scss']
})
export class MaritalStatusSelectionComponent implements OnInit {
  @Input() control: FormControl;
  @Input() parentName: string;
  @Input() placeholder: string;

  public options: object;

  constructor(private util: UtilService) {}

  ngOnInit() {
    if(this.parentName && this.parentName == "motorCar") {
      this.options = this.util.enumToArray(UpdatedMaritalStatus);
    } else {
      this.options = this.util.enumToArray(MaritalStatus);
    }

    if(typeof this.placeholder === 'undefined') {
      this.placeholder = 'Select Civil Status';
    }
  }
}
