import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import {
  EPayment,
  HealthInsurance,
  HealthPolicyHolder,
  MotorExistingCustomer,
  MotorWizard,
  PolicyDetail,
  PrePurchase,
  RateCard,
  TravelInsurance,
  PrePurchaseCar,
  PrePurchaseMortgagee,
  MotorCarWizard
} from '@insureshop/shared/models';
import { HealthInsuranceClaim } from '@insureshop/shared/models/health-insurance-claim.model';
import { TravelInsuranceClaim } from '@insureshop/shared/models/travel-insurance-claim.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { Generic} from '@insureshop/shared/models'
import { PromoCodeAction } from '@insureshop/modules/insurance/motor-car/enums';

@Injectable()
export class InsuranceService {
  private headers = { headers: new HttpHeaders().set('exclude', 'true') };
  promoCode: string;

  constructor(
    private httpClient: HttpClient) {}

  public purchaseTravel(insurance: TravelInsurance): Observable<any> {
    return this.httpClient.post<any>('/api/v1/insurance/travel/purchase', insurance);
  }

  public purchaseHealth(insurance: HealthInsurance): Observable<HealthInsurance> {
    return this.httpClient.post<HealthInsurance>('/api/v1/insurance/health/purchase', insurance);
  }

  public payTravel(id: number, paymentType?: string): Observable<any> {
    let params = new HttpParams();
    if (paymentType) {
      params = params.set('paymentType', paymentType);
    }
    return this.httpClient.put<any>(
      `/api/v1/insurance/travel/pay/${id}`,
      null,
      { params }
    );
  }

  public payTravelByInstallment(id: number, paymentType?: string): Observable<any> {
    let params = new HttpParams();
    if (paymentType) {
      params = params.set('paymentType', paymentType);
    }
    return this.httpClient.put<any>(`/api/v1/insurance/travel/pay-installment/${id}`, null, { params });
  }

  public payHealth(id: number, paymentType?: string): Observable<any> {
    let params = new HttpParams();
    if (paymentType) {
      params = params.set('paymentType', paymentType);
    }
    return this.httpClient.put<any>(
      `/api/v1/insurance/health/pay/${id}`,
      null,
      { params }
    );
  }

  public payHealthByInstallment(id: number, paymentType?: string): Observable<any> {
    let params = new HttpParams();
    if (paymentType) {
      params = params.set('paymentType', paymentType);
    }
    return this.httpClient.put<any>(`/api/v1/insurance/health/pay-installment/${id}`, null, { params });
  }

  public checkPaymentStatus(id: number): Observable<any> {
    return this.httpClient.get<any>(`/api/v1/insurance/payment/status/insurance/${id}`);
  }

  public checkInstallmentStatus(referenceNumber: string): Observable<any> {
    return this.httpClient.get<any>(`/api/v1/insurance/payment/status/insurance/installment/${referenceNumber}`)
  }

  public setPromoCode(promoCode: string) {
    this.promoCode = promoCode;
  }

  public getPromoCode() {
    return this.promoCode;
  }

  public paymentGateway(form: EPayment): Observable<any> {
    const payload = new FormData();
    payload.append('MerchantCode', form.merchantCode);
    payload.append('PaymentId', form.paymentId.toString());
    payload.append('RefNo', form.referenceNumber);
    payload.append('Amount', form.amount.toString());
    payload.append('Currency', form.currency);
    payload.append('ProdDesc', form.productDescription);
    payload.append('UserName', form.name);
    payload.append('UserEmail', form.emailAddress);
    payload.append('UserContact', form.mobileNumber);
    payload.append('Remark', form.remarks || '');
    payload.append('Lang', form.encoding);
    payload.append('Signature', form.signature);
    payload.append('ResponseURL', form.successRedirectURL);
    payload.append('BackendURL', form.successPostURL);

    return this.httpClient.post<any>(`/epayment/entry.asp`, payload);
  }

  public checkIfMinor(birthDate: Date): Observable<any> {
    let parameters = new HttpParams();
    parameters = parameters.set('birthDate', birthDate.toISOString());

    return this.httpClient.post<any>(`/api/v1/insurance/travel/check-if-minor`, parameters);
  }

  public calculateTravel(policyHolders: any): Observable<any> {
    return this.httpClient.post<any>(
      `/api/v1/insurance/travel/calculate`,
      policyHolders,
      this.headers
    );
  }

  public calculateHealth(policyHolders: any): Observable<any> {
    return this.httpClient.post<any>(
      `/api/v1/insurance/health/calculate`,
      policyHolders,
      this.headers
    );
  }

  public validateGift(id: number, activationKey: string): Observable<any> {
    return this.httpClient.post<any>(
      `/api/v1/insurance/health/gift-recipient/${id}/activation-key/${activationKey}/validate`,
      null,
      this.headers
    );
  }

  public claimGift(id: number, activationKey: string, receiverInfo: HealthPolicyHolder): Observable<any> {
    return this.httpClient.put<any>(
      `/api/v1/insurance/health/gift-recipient/${id}/activation-key/${activationKey}/claim`,
      receiverInfo,
      this.headers
    );
  }

  public generateTravelReport(email: string, startDate: string, endDate: string) {
    return this.httpClient.post<any>(
      `/api/v1/insurance/travel/report-range?email=${email}&from=${startDate}&to=${endDate}`,
      {
      }
    );
  }

  public generateTravelClaimsReport(email: string, startDate: string, endDate: string) {
    return this.httpClient.post<any>(
      `/api/v1/insurance/travel-insurance-claim/report-range?email=${email}&from=${startDate}&to=${endDate}`,
      {
      }
    );
  }

  public downloadTravelReport(startDate: string, endDate: string) {
    return window.open(`/api/v1/insurance/travel/download/report-range?from=${startDate}&to=${endDate}`, "_blank");
  }

  public downloadTravelClaimsReport(startDate: string, endDate: string) {
    return window.open(`/api/v1/insurance/travel-insurance-claim/download/report-range?from=${startDate}&to=${endDate}`, "_blank");
  }

  public generateMedicashReport(email: string, startDate: string, endDate: string) {
    return this.httpClient.post<any>(
      `/api/v1/insurance/health/report-range?email=${email}&from=${startDate}&to=${endDate}`,
      {
      }
    );
  }

  public generateMedicashRegistrationReport(email: string, startDate: string, endDate: string) {
    return this.httpClient.post<any>(
      `/api/v1/insurance/health/report-range/registration?email=${email}&from=${startDate}&to=${endDate}`,
      {
      }
    );
  }

  public generateMedicashClaimsReport(email: string, startDate: string, endDate: string) {
    return this.httpClient.post<any>(
      `/api/v1/insurance/health-insurance-claim/report-range?email=${email}&from=${startDate}&to=${endDate}`,
      {
      }
    );
  }

  public downloadMedicashReport(startDate: string, endDate: string) {
    return window.open(`/api/v1/insurance/health/download/report-range?from=${startDate}&to=${endDate}`, "_blank");
  }

  public downloadMedicashRegistrationReport(startDate: string, endDate: string) {
    return window.open(`/api/v1/insurance/health/download/report-range/registration?from=${startDate}&to=${endDate}`, "_blank");
  }

  public downloadMedicashClaimsReport(startDate: string, endDate: string) {
    return window.open(
      `/api/v1/insurance/health-insurance-claim/download/report-range/registration?from=${startDate}&to=${endDate}`,
      "_blank"
    );
  }

  public generateMotorReport(email: string, startDate: string, endDate: string): Observable<any> {
    return this.httpClient.post<any>(
      `/api/v1/insurance/motor/report-range?email=${email}&from=${startDate}&to=${endDate}`,
      {
      }
    );
  }

  public generateMotorPrepurchaseReport(email: string, startDate: string, endDate: string): Observable<any> {
    return this.httpClient.post<any>(
      `/api/v1/insurance/pre-purchase-inquiry/report-range?email=${email}&from=${startDate}&to=${endDate}`,
      {
      }
    );
  }

  public generateMotorQuotationReport(email: string, startDate: string, endDate: string): Observable<any> {
    return this.httpClient.post<any>(
      `/api/v1/insurance/motor/report-range/quotation?email=${email}&from=${startDate}&to=${endDate}`,
      {
      }
    );
  }

  public downloadMotorReport(startDate: string, endDate: string) {
    return window.open(
      `/api/v1/insurance/motor/download/report-range?from=${startDate}&to=${endDate}`,
      "_blank"
    );
  }

  public downloadMotorPrepurchaseReport(startDate: string, endDate: string) {
    return window.open(
      `/api/v1/insurance/pre-purchase-inquiry/download/motor/report-range?from=${startDate}&to=${endDate}`,
      "_blank"
    );
  }

  public downloadMotorQuotationReport(startDate: string, endDate: string) {
    return window.open(
      `/api/v1/insurance/motor/download/report-range/quotation?from=${startDate}&to=${endDate}`,
      "_blank"
    );
  }

  public fileTravelInsuranceClaim(claimDetails: TravelInsuranceClaim): Observable<any> {
    return this.httpClient.post<any>(
      `/api/v1/insurance/travel-insurance-claim/file`,
      claimDetails
    );
  }

  public fileTravelInsuranceClaim2(gen: Generic): Observable<any> {
    return this.httpClient.post<any>(
      `/api/v1/insurance/travel-insurance-claim/file2`,
      gen
    );
  }

  public fileHealthInsuranceClaim(claimDetails: HealthInsuranceClaim): Observable<any> {
    return this.httpClient.post<any>(
      `/api/v1/insurance/health-insurance-claim/file`,
      claimDetails
    );
  }

  public fileHealthInsuranceClaim2(gen: Generic): Observable<any> {
    return this.httpClient.post<any>(
      `/api/v1/insurance/health-insurance-claim/file2`,
      gen
    );
  }

  public getTravelInsuranceClaimDownloadReq(fileName: string): Observable<any> {
    return this.httpClient.get(
      `/api/v1/insurance/travel-insurance-claim/download/${fileName}`,
      {
        responseType: 'blob',
        headers: { exclude: 'true' }
      }
    );
  }

  public getHealthInsuranceClaimDownloadReq(fileName: string): Observable<any> {
    return this.httpClient.get(
      `/api/v1/insurance/health-insurance-claim/download/${fileName}`,
      {
        responseType: 'blob',
        headers: { exclude: 'true' }
      }
    );
  }

  public travelSendEmailNotification(id: number): Observable<any> {
    return this.httpClient.post<any>(
      `/api/v1/insurance/travel-insurance-claim/${id}/submit`,
      {}
    );
  }

  public healthSendEmailNotification(id: number): Observable<any> {
    return this.httpClient.post<any>(
      `/api/v1/insurance/health-insurance-claim/${id}/submit`,
      {}
    );
  }

  public healthMedicashCardValidation(form: object): Observable<any> {
    return this.httpClient.post<any>(
      `/api/v1/insurance/health/medicash/validate`,
      form
    );
  }

  public healthMedicashCardActivation(form: object): Observable<any> {
    return this.httpClient.post<any>(
      `/api/v1/insurance/health/medicash/activate`,
      form
    );
  }

  public getMotorRateCard(form: any): Observable<RateCard> {
    return this.httpClient.post<RateCard>(
      `/api/v1/insurance/motor/rate-card`,
      form,
      this.headers
    );
  }

  public getCarTPPDRates(): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.motorCompreConfig.url}/hodu/service-quotation/v1.0/mc/tppd`
    );
  }

  public getCarTPBIRates(): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.motorCompreConfig.url}/hodu/service-quotation/v1.0/mc/tpbi`
    );
  }

  public getCarPPARate(): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.motorCompreConfig.url}/hodu/service-quotation/v1.0/mc/ppa`
    );
  }

  public sendFailedEligibilityEmail(form: any): Observable<any>  {
    return this.httpClient.post<any>(
      `${environment.motorCompreConfig.url}/hodu/service-quotation/v1.0/mc/quotes/eligibility/hit`,
      form
    );
  }

  public getCarDeductibleRates(fmv: any) {
    let params = new HttpParams();
    params = params.set('fmv',fmv);
    return this.httpClient.post<any>(
      `${environment.motorCompreConfig.url}/hodu/service-quotation/v1.0/mc/quotes/deductibles/${fmv}`,
      null
    );
  }

  public calculateMotorInsurance(form: any): Observable<any> {
    return this.httpClient.post<any>(
      `/api/v1/insurance/motor/calculate`,
      form,
      this.headers
    );
  }

  public calculateMotorCarInsurance(form: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.motorCompreConfig.url}/hodu/service-quotation/v1.0/mc/quotes/compute`,
      form
    );
  }

  public motorCarInsuranceIndividualSaveCoverage(form: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.motorCompreConfig.url}/hodu/service-quotation/v1.0/mc/quotes/individual/custom/send`,
      form
    );
  }

  public motorCarInsuranceBusinessSaveCoverage(form: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.motorCompreConfig.url}/hodu/service-quotation/v1.0/mc/quotes/business/custom/send`,
      form
    );
  }

  public motorCarInsuranceIndividualSaveUploadLater(form: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.motorCompreConfig.url}/hodu/service-quotation/v1.0/mc/quotes/individual/upload/send`,
      form
    );
  }

  public motorCarInsuranceBusinessSaveUploadLater(form: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.motorCompreConfig.url}/hodu/service-quotation/v1.0/mc/quotes/business/upload/send`,
      form
    );
  }

  public resendMotorCarInsurance(encryptedId:string, resumePage: string): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.motorCompreConfig.url}/hodu/service-quotation/v1.0/mc/quotes/resend`,
      {
        encryptedId,
        resumePage
      }
    );
  }

  public validateMotorPurchasePrice(purchasePrice: number, modelId: number): Observable<boolean> {
    let params = new HttpParams();
    params = params.append('purchasePrice', purchasePrice.toString());
    params = params.append('modelId', modelId.toString());
    return this.httpClient.post<boolean>(
      `/api/v1/insurance/motor/validate-purchase-price`,
      null,
      {
        ...this.headers,
        params
      }
    );
  }

  public motorInsuranceSave(motorInsurance: any): Observable<any> {
    return this.httpClient.post<any>(`/api/v1/insurance/motor`, motorInsurance);
  }

  public motorValidation(id: number, info: any): Observable<any> {
    return this.httpClient.post<any>(
      `/api/v1/insurance/motor/${id}/validate-existing-motor`,
      info
    );
  }

  public motorResumeLater(
    id: number,
    resumePoint: string,
    emailAddress: string,
    mobileNumber?: string,
    firstName?: string,
    lastName?: string
  ) {
    const payload = new FormData();
    if (mobileNumber) {
      payload.append('mobileNumber', mobileNumber);
    }
    if (firstName) {
      payload.append('firstName', firstName);
    }
    if (lastName) {
      payload.append('lastName', lastName);
    }

    payload.append('email', emailAddress);
    payload.append('resumePoint', resumePoint);

    return this.httpClient.post(
      `/api/v1/insurance/motor/${id}/resume-later`,
      payload
    );
  }

  public motorUploadAttachment(attachment: any, id: number): Observable<Response> {
    return this.httpClient.post<any>(
      `/api/v1/insurance/motor/upload/${id}`,
      attachment
    );
  }

  public motorNotifExistingClient(id: number): Observable<any> {
    return this.httpClient.post<any>(
      `/api/v1/insurance/motor/${id}/notify-pioneer`,
      null
    );
  }

  public motorCustomerInfo(form: MotorExistingCustomer) {
    return this.httpClient.post<MotorExistingCustomer>(
      `/api/v1/insurance/motor/customer-info`,
      form
    );
  }

  public purchaseMotor(insurance: any): Observable<any> {
    return this.httpClient.post<any>(
      `/api/v1/insurance/motor/purchase`,
      insurance
    );
  }

  public payMotor(id: number, paymentType?: string): Observable<any> {
    let params = new HttpParams();
    if (paymentType) {
      params = params.set('paymentType', paymentType);
    }
    return this.httpClient.put<any>(`/api/v1/insurance/motor/pay/${id}`, null, { params });
  }

  public purchaseMotorCarIndividual(insurance: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.motorCompreConfig.url}/hodu/service-motor-compre/v1.0/mc/individual/save`,
      insurance
    );
  }

  public purchaseMotorCarBusiness(insurance: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.motorCompreConfig.url}/hodu/service-motor-compre/v1.0/mc/organization/save`,
      insurance
    );
  }

  public payMotorCar(id: number, paymentType: string, gateway: string): Observable<any> {
    let params = new HttpParams();
    if (paymentType) {
      params = params
        .set('paymentType', paymentType)
        .set('gateway', gateway)
    }
    return this.httpClient.put<any>(`${environment.motorCompreConfig.url}/hodu/service-motor-compre/v1.0/mc/pay/${id}`, null, { params });
  }

  public ctplAuth(vehicleInfo: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.motorCompreConfig.url}/hodu/service-quotation/v1.0/ctpl/authenticate`,
      vehicleInfo
    );
  }

  public ctplSaveDraft(form: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.ctplConfig.url}/api/v1/session/saveandsend`,
      form
    );
  }

  public getCtplCOC(): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.motorCompreConfig.url}/hodu/service-quotation/v1.0/ctpl/cocNumber`
    );
  }

  public validateEngineChassis(form: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.motorCompreConfig.url}/hodu/service-quotation/v1.0/ctpl/validate/enginechassis`,
      form
    );
  }

  public payMotorByInstallment(id: number, paymentType?: string): Observable<any> {
    let params = new HttpParams();
    if (paymentType) {
      params = params.set('paymentType', paymentType);
    }
    return this.httpClient.put<any>(`/api/v1/insurance/motor/pay-installment/${id}`, null, { params });
  }

  public prepurchaseInquiry(inquiry: PrePurchase) {
    return this.httpClient.post<PrePurchase>(
      `/api/v1/insurance/pre-purchase-inquiry/inquire`,
      inquiry
    );
  }

  public carPrepurchaseInquiry(inquiry: PrePurchaseCar) {

    return this.httpClient.post<PrePurchaseCar>(
      `${environment.motorCompreConfig.url}/hodu/service-motor-compre/v1.0/inquiry/car`,
      inquiry);
  }
  public carPrepurchaseInquiryRefNo() {
    return this.httpClient.post<any>(
      `${environment.motorCompreConfig.url}/hodu/service-motor-compre/v1.0/inquiry/carReferenceNo`,
      null
    );
  }

  public mortgageePrepurchaseInquiry(inquiry: PrePurchaseMortgagee) {
    return this.httpClient.post<PrePurchaseMortgagee>(
      `${environment.motorCompreConfig.url}/hodu/service-motor-compre/v1.0/inquiry/mortgagee`,
      inquiry,
    );
  }
  public mortgageePrepurchaseInquiryRefNo() {
    return this.httpClient.post<any>(
      `${environment.motorCompreConfig.url}/hodu/service-motor-compre/v1.0/inquiry/mortgageeReferenceNo`,
      null
    );
  }

  public prepurchaseCarUploadAttachment(ref: string, attachment: File) {
    let parameters = new HttpParams();
    const payload = new FormData();
    payload.append('file', attachment);
    parameters = parameters
      .set('reference', ref);
    return this.httpClient.post<any>(
      `${environment.motorCompreConfig.url}/hodu/service-motor-compre/v1.0/document/upload`,
      payload,
      {
        params: parameters
      }
    );
  }

  public fetchAttachmentData(refArray: string[]) {
    return this.httpClient.post<any>(
      `${environment.motorCompreConfig.url}/hodu/service-motor-compre/v1.0/document/get`,
      refArray
    );
  }

  public prepurchaseUploadAttachment(id: number, attachment: File) {
    const payload = new FormData();
    payload.append('file', attachment);
    return this.httpClient.post(
      `/api/v1/insurance/pre-purchase-inquiry/upload/${id}`,
      payload
    );
  }

  public prepurchaseInquirySubmission(id: number) {
    return this.httpClient.post(
      `/api/v1/insurance/pre-purchase-inquiry/${id}/submit`,
      null
    );
  }

  public resumeMotorTransaction(resumeId: string): Observable<any> {
    return this.httpClient.post<any>(
      `/api/v1/insurance/motor/resumeId/${resumeId}/validate`,
      null
    );
  }

  public resumeMotorCarTransaction(encryptedId: string): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.motorCompreConfig.url}/hodu/service-quotation/v1.0/mc/quotes/resume/validate`, {
        encryptedId,
        resumePage:null
      });
  }

  public getMotorInsuranceByCodeAndId(id: number, code: string): Observable<MotorWizard> {
    return this.httpClient.get<MotorWizard>(`/api/v1/insurance/motor/${id}/code/${code}/get`);
  }

  public getMotorCarIndividualInsuranceById(resumePage: string ,encryptedId: string): Observable<MotorCarWizard> {
    return this.httpClient.post<MotorCarWizard>(
      `${environment.motorCompreConfig.url}/hodu/service-quotation/v1.0/mc/quotes/individual/resume/fetch`, {
        encryptedId,
        resumePage
      });
  }

  public getMotorCarBusinessInsuranceById(resumePage: string ,encryptedId: string): Observable<MotorCarWizard> {
    return this.httpClient.post<MotorCarWizard>(
      `${environment.motorCompreConfig.url}/hodu/service-quotation/v1.0/mc/quotes/business/resume/fetch`, {
        encryptedId,
        resumePage
      });
  }

  public getMotorCarOfficialReceipt(paymentId: any) {
    return this.httpClient.get(
      `${environment.motorCompreConfig.url}/hodu/service-motor-compre/v1.0/mc/payment/status/insurance/or/print/${paymentId}`
    );
  }

  // public validatePromoCode(code: string): Observable<any> {
  //   let parameters = new HttpParams();
  //   parameters = parameters
  //     .set('promocode', code);
  //   return this.httpClient.post<any>(
  //     `api/v1/admin/agent/promocode`,
  //     null,
  //     {
  //       params: parameters
  //     }
  //   )
  // }

  public verifyDriverBirthDate(date: Date): Observable<any> {
    let dateParam = date.toISOString();
    return this.httpClient.get<any>(`/api/v1/insurance/motor/driver/birth-date/verify?birthDate=${dateParam}`);
  }

  public verifyDrivingExperience(ln: string): Observable<any> {
    return this.httpClient.get<any>(`/api/v1/insurance/motor/driver/driving-experience/verify?licenseNumber=${ln}`);
  }

  public getOfficialReceipt(id: number): Observable<Blob> {
    return this.httpClient.get(
      `/api/v1/insurance/content/insurance/${id}/official-receipt`,
      {
        responseType: 'blob',
        headers: { exclude: 'true' }
      }
    );
  }

  public requestSubscription(emailAddress: string, mobileNumber: string): Observable<any> {
    return this.httpClient.post<any>(
      `/api/v1/admin/subscription`,
      {
        emailAddress,
        mobileNumber,
      }
    );
  }

  public getPolicy(id: number, insuranceId: number): Observable<Blob> {
    return this.httpClient.get(
      `/api/v1/insurance/content/insurance/${insuranceId}/policy-holder/${id}/policy`,
      {
        responseType: 'blob',
        headers: { exclude: 'true' }
      }
    );
  }

  public getInsuranceListByStatus(status: string): Observable<any> {
    return this.httpClient.get<any>(`/api/v1/insurance/policy-holder?status=${status}`);
  }

  public getAccihealthInsuranceList(keycloakID: string, active: boolean): Observable<any> {
    const status = active ? 'active' : 'inactive';
    return this.httpClient.get<any>(`${environment.accihealthConfig.url}/api/v1/insurance/${keycloakID}/${status}`);
  }

  public getCtplInsuranceList(keycloakID: string, active: boolean): Observable<any> {
    const status = active ? 'active' : 'inactive';
    return this.httpClient.get<any>(`${environment.ctplConfig.url}/api/v1/insurance/${keycloakID}/${status}`);
  }

  public getInsuranceDetailById(id: number, product: string): Observable<PolicyDetail> {
    let productDetail = '';
    switch (product) {
      case 'MediCash':
        productDetail = 'health';
        break;
      case 'SafeTrip':
        productDetail = 'travel';
        break;
      default:
        productDetail = 'motor';
    }
    return this.httpClient.get<PolicyDetail>(`/api/v1/insurance/policy-holder/${productDetail}/${id}`);
  }

  public cancelInsurance(urlParam: string, id: number, data: object): Observable<any> {
    return this.httpClient.put<any>(`/api/v1/insurance/policy-holder/${urlParam}/${id}/cancel`, data);
  }

  public travelAmendmentRequest(data: any): Observable<any> {
    return this.httpClient.post<any>(`/api/v1/insurance/travel-amendment/file`, data);
  }

  public getSalesReport(startDate: any, endDate: any): Observable<any> {
    return this.httpClient.get<any>(`/api/v1/insurance/insurance-dashboard/sales?startDate=${startDate}&endDate=${endDate}`);
  }

  public getPolicyHolderHistory(id: number): Observable<any> {
    return this.httpClient.get<any>(`/api/v1/insurance/policy-holder-history/policy/${id}`);
  }

  public $reportStatus = new BehaviorSubject<any>({
    sending: false,
    sendSuccess: null,
    reportName: null
  });

  // Promo Code Service
  public applyPromoCode(payload: any, product: string): Observable<any> {
    return this.httpClient.post<any>(
      `/api/v1/insurance/${product}/apply-promo`,
      payload
    );
  }

  getTransactionData(id: any): Observable<any> {
    return this.httpClient.get<any>(`/api/v1/insurance/${id}/transaction-data`);
  }



  public generateCtplTransactionReport(email: string, from: string, to: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.ctplConfig.url}/api/v1/cocaf/report/transaction/ctpl?email=${email}&from=${from}&to=${to}`
    );
  }

  public generateCtplProductionReport(email: string, from: string, to: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.ctplConfig.url}/api/v1/insurance/report/production/ctpl?email=${email}&from=${from}&to=${to}`
    );
  }

  public generateCtplFailedReport(email: string, from: string, to: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.ctplConfig.url}/api/v1/cocaf/report/failed/ctpl?email=${email}&from=${from}&to=${to}`
    );
  }

  public generateOoopsieSalesReport(email: string, from: string, to: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.accihealthConfig.url}/api/v1/insurance/report/ooopsie?email=${email}&from=${from}&to=${to}`
    );
  }

  public generateHospicashSalesReport(email: string, from: string, to: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.accihealthConfig.url}/api/v1/insurance/report/hospicash?email=${email}&from=${from}&to=${to}`
    );
  }

  public generateOoopsieSalesReportCSV(from: string, to: string) {
    return window.open(
      `${environment.accihealthConfig.url}/api/v1/insurance/report/ooopsie/generate?from=${from}&to=${to}`,
      "_blank"
    );
  }

  public generateHospicashSalesReportCSV(from: string, to: string) {
    return window.open(
      `${environment.accihealthConfig.url}/api/v1/insurance/report/hospicash/generate?from=${from}&to=${to}`,
      "_blank"
    );
  }

  public generateCtplTransactionReportCSV(from: string, to: string) {
    return window.open(
      `${environment.ctplConfig.url}/api/v1/cocaf/report/transaction/ctpl/generate?from=${from}&to=${to}`,
      "_blank"
    );
  }

  public generateCtplProductionReportCSV(from: string, to: string) {
    return window.open(
      `${environment.ctplConfig.url}/api/v1/payments/report/production/ctpl/generate?from=${from}&to=${to}`,
      "_blank"
    );
  }

  public generateCtplFailedReportCSV(from: any, to: any) {
    return window.open(
      `${environment.ctplConfig.url}/api/v1/cocaf/report/failed/ctpl/generate?from=${from}&to=${to}`,
      "_blank"
    );
  }

  public generateBillerReport(email: string, startDate: string, endDate: string, prodType: string): Observable<any> {
    return this.httpClient.post<any>(
      `/api/v1/billing/biller/report?email=${email}&from=${startDate}&to=${endDate}&prodType=${prodType}`,
      {
      }
    );
  }

  public downloadBillerReport(prodType: string, startDate: string, endDate: string) {
    return window.open(`/api/v1/billing/biller/download/report-range?prodType=${prodType}&from=${startDate}&to=${endDate}`, "_blank");
  }

  public checkAmla(name: string) {
    return this.httpClient.get<any>(
      `${environment.motorCompreConfig.url}/hodu/service-motor-compre/v1.0/mc/amla/validate?name=${name}`,
    );
  }

  // MATCH MY COVERAGE
  public sendMatchMyCoverageInitialEmail(data: any) {
    return this.httpClient.post<any>(
      `${environment.motorCompreConfig.url}/hodu/service-quotation/v1.0/match/sendEmailConfirmation`, data);
  }

  // MATCH MY COVERAGE BUSINESS
  public sendMatchMyCoverageInitialEmailForBusiness(data: any) {
    return this.httpClient.post<any>(
      `${environment.motorCompreConfig.url}/hodu/service-quotation/v1.0/match/sendEmailConfirmationBusiness`, data);
  }

  // MATCH MY COVERAGE ADMIN MODULE FETCHING
  public fetchMatchMyCoverageData(id: string, resumePoint?: string) {
    return this.httpClient.post<any>(
      `${environment.motorCompreConfig.url}/hodu/service-quotation/v1.0/match/fetch`, {
        encryptedId: id,
        resumePage: resumePoint
      }
    );
  }

  // MATCH MY COVERAGE - ADMIN MODULE - EMAIL SENDING APPROVED
  public sendApprovedEmail(id: string, request) {
    return this.httpClient.put<any>(
      `${environment.motorCompreConfig.url}/hodu/service-quotation/v1.0/match/update/${id}`,
      request,
    );
  }

  // MATCH MY COVERAGE - ADMIN MODULE - EMAIL SENDING DECLINED
  public sendDeclinedEmail(data: any) {
    return this.httpClient.post<any>(
      `${environment.motorCompreConfig.url}/hodu/service-quotation/v1.0/match/sendStatusEmail`, data);
  }
  public applyIndividualPromoCode(action: PromoCodeAction, promoCode: string, insuranceData: any) {
    return this.httpClient.post<any>(`${environment.motorCompreConfig.url}/hodu/service-motor-compre/v1.0/mc/individual/promo/${action}/${promoCode}`, insuranceData, {});
  }
  public applyOrganizationPromoCode(action: PromoCodeAction, promoCode: string, insuranceData: any) {
    return this.httpClient.post<any>(`${environment.motorCompreConfig.url}/hodu/service-motor-compre/v1.0/mc/organization/promo/${action}/${promoCode}`, insuranceData, {})
  }
}
