import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@insureshop/core/services/auth.service';
import { LoginComponent } from '@insureshop/shared/components/modals/login/login.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, throwError} from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/internal/operators';
import { environment } from '@env/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    private authService: AuthService,
    private router: Router,
    private modalService: NgbModal) { }

  private applyCredentials = (request: HttpRequest<any>) => {
    const token = this.authService.getToken();

    // checks if url has 'hodu' string
    if(request.url.includes('hodu')) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${environment.apiToken}`
        }
      });
    } else {
      if (token) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`
          }
        });
      }
    }
    return request;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = this.applyCredentials(request);

    return next.handle(request).pipe(
      catchError((error) => {

        // refresh token here?
        if (error.status === 401) {
          if (this.refreshTokenInProgress) {
            return this.refreshTokenSubject.pipe(
              filter(token => token != null),
              take(1),
              switchMap(() => {
                return next.handle(this.applyCredentials(request));
              }));
          } else {
            this.refreshTokenInProgress = true;
            this.refreshTokenSubject.next(null);
            this.authService.removeToken();

            return this.authService.refreshToken(this.authService.getRefreshToken())
              .pipe(
                switchMap((response: any) => {
                  const newToken = response['access_token'];
                  if (newToken) {
                    this.refreshTokenSubject.next(newToken);

                    return next.handle(this.applyCredentials(request));
                  }

                  this.authService.removeAllToken();
                  if (this.modalService.hasOpenModals()) {
                    this.modalService.dismissAll();
                  }
                  this.modalService.open(LoginComponent, { size: 'lg' }).result.catch(() => {
                    this.authService.logout().subscribe(() => true,
                      () => {
                        this.authService.$userState.next(null);
                        this.authService.removeAllToken();
                      });
                  });
                }),
                catchError((e) => {
                  this.router.navigate(['']);
                  return throwError(e);
                }),
                finalize(() => {
                  this.refreshTokenInProgress = false;
                })
              )
          }
        }
        // return all others errors
        return throwError(error);

      })) as any;
  }
}
