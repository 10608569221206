export class PiscBillerDetails {
  pioneerReferenceNumber: string;
  policyNumber: string;
  description?: string;
  insuredName: string;
  address: string;
  emailAddress: string;
  productType: string;
  endorsements: Endorsements[];
  totalVat: number;
  totalPremium: number;
  totalPremiumTax: number;
  totalLgt: number;
  totalDst: number;
  totalFst: number;
  totalOtherCharges: number;
  grandTotal: number;
}

export class Endorsements {
  pioneerReferenceNumber: string;
  policyNumber: string;
  insuredName: string;
  address: string;
  emailAddress: string;
  premiumTax: number;
  dst: number;
  lgt: number;
  premium: number;
  productType: string;
  fst: number;
  otherCharges: number;
  vat: number;
  premiumTaxTag: number;
  price: number;
}
