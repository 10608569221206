import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '@env/environment';
import { AuthService, CmsService } from '@insureshop/core/services';
import { LoginComponent } from '@insureshop/shared/components/modals/login/login.component';
import { User } from '@insureshop/shared/models/user.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit, OnDestroy {
  public products = [];
  public header: any;
  hospicashLink: string;
  ooopsieLink: string;
  ctplLink: string;

  constructor(
    private authService: AuthService,
    private cmsService: CmsService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {

    //initial load
    // NEED TO ADD FUNCTION FOR SPLITTING ROUTE AND QUERYPARAM

    if (this.authService.getSessionItem('hash_key')) {
      this.hospicashLink = environment.accihealthConfig.hospicash + '?agentcode=' + this.authService.getSessionItem('hash_key');
      this.ooopsieLink = environment.accihealthConfig.ooopsie + '?agentcode=' + this.authService.getSessionItem('hash_key');
      this.ctplLink = environment.ctplConfig.ctpl + '?agentcode=' + this.authService.getSessionItem('hash_key');
    } else if (this.authService.getLocalizeItem('hash_key')) {
      this.hospicashLink = environment.accihealthConfig.hospicash + '?agentcode=' + this.authService.getLocalizeItem('hash_key');
      this.ooopsieLink = environment.accihealthConfig.ooopsie + '?agentcode=' + this.authService.getLocalizeItem('hash_key');
      this.ctplLink = environment.ctplConfig.ctpl + '?agentcode=' + this.authService.getLocalizeItem('hash_key');
    } else {
      this.hospicashLink = environment.accihealthConfig.hospicash;
      this.ooopsieLink = environment.accihealthConfig.ooopsie;
      this.ctplLink = environment.ctplConfig.ctpl;
    }
    this.cmsService.getProductOfferings()
      .pipe(untilComponentDestroyed(this))
      .subscribe(products => this.products = products);

    this.cmsService.getHeader()
      .pipe(untilComponentDestroyed(this))
      .subscribe(header => this.header = header);

    if (this.authService.getToken()) {
      this.authService.getLoggedInUser().subscribe((user: User) => this.authService.$userState.next(user));
    }
  }

  goToProducts(product: string) {
    switch(product) {
      case 'ooopsie':
        window.location.href = this.ooopsieLink;
        break;
      case 'hospicash':
        window.location.href = this.hospicashLink;
        break;
      case 'ctpl':
        window.location.href = this.ctplLink;
        break;
    }
  }

  goToProductOoopsie() {
    if (this.authService.getSessionItem('hash_key')) {
      window.location.href =  environment.accihealthConfig.ooopsie + '?agentcode=' + this.authService.getSessionItem('hash_key');
    } else if (this.authService.getLocalizeItem('hash_key')) {
      window.location.href = environment.accihealthConfig.ooopsie + '?agentcode=' + this.authService.getLocalizeItem('hash_key');
    } else {
      window.location.href = environment.accihealthConfig.ooopsie;
    }
  }
  goToProductHospicash() {
    if (this.authService.getSessionItem('hash_key')) {
      window.location.href =  environment.accihealthConfig.hospicash + '?agentcode=' + this.authService.getSessionItem('hash_key');
    } else if (this.authService.getLocalizeItem('hash_key')) {
      window.location.href = environment.accihealthConfig.hospicash + '?agentcode=' + this.authService.getLocalizeItem('hash_key');
    } else {
      window.location.href = environment.accihealthConfig.hospicash;
    }
  }
  goToProductCtpl() {
    if (this.authService.getSessionItem('hash_key')) {
      window.location.href =  environment.ctplConfig.ctpl + '?agentcode=' + this.authService.getSessionItem('hash_key');
    } else if (this.authService.getLocalizeItem('hash_key')) {
      window.location.href = environment.ctplConfig.ctpl + '?agentcode=' + this.authService.getLocalizeItem('hash_key');
    } else {
      window.location.href = environment.ctplConfig.ctpl;
    }
  }

  goToDayTripSureTrip() {
    window.location.href = environment.sureDayTripConfig.url;
  }

  goToPetInsurance() {
    window.location.href = environment.petConfig.url;
  }

  goToOFWInsurance() {
    window.location.href = environment.ofwConfig.url;
  }

  goToHomE() {
    window.location.href = environment.homeConfig.url;
  }

  goToBuhayAtAksidente() {
    window.location.href = environment.buhayConfig.aksidente;
  }

  goToBuhayAtBahay() {
    window.location.href = environment.buhayConfig.bahay;
  }

  goToBuhayAtOspital() {
    window.location.href = environment.buhayConfig.ospital;
  }

  public login() {
    this.modalService.open(LoginComponent, { size: 'lg' });
  }

  public isLoggedIn(): string {
    return this.authService.getToken();
  }

  public currentUser(): Observable<User> {
    return this.authService.getCurrentUser();
  }

  public logout(): void {
    this.authService.logout().subscribe(
      () => true,
      () => {
        this.authService.$userState.next(null);
        this.authService.removeToken();
        this.authService.removeKeycloakId();
      }
    );
  }

  ngOnDestroy() {}
}
