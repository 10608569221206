import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PROPAGATION_FRAME_ID } from '@insureshop/shared/constants/session-propagation-constants';
import { User } from '@insureshop/shared/models/user.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AesService } from '@insureshop/core/services/aes.service';
import { Generic} from '@insureshop/shared/models';

@Injectable()
export class AuthService {
  public $userState: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  private headers = { headers: new HttpHeaders().set('exclude', 'true') };

  constructor(private httpClient: HttpClient, public aes: AesService) {}

  public login(credentials: any): Observable<any> {
    return this.httpClient
      .post<any>(
        '/api/v1/admin/authentication/login',
        credentials,
        this.headers
      )
      .pipe(tap((response: any) => {
        const jwt = this.parseJwt(response['access_token']);
        this.saveKeycloakID(jwt.sub);
        this.saveToken(response['access_token']);
        this.saveRefreshToken(response['refresh_token']);
        this.getLoggedInUser().subscribe((user: Object) => {
          let serializedUser = User.serializeUser(user);
          this.$userState.next(serializedUser);
          this.propagateLogin(serializedUser);

          return response;
        });
      }));
  }

  public login2(gen: Generic): Observable<any> {
    return this.httpClient
      .post<any>(
        '/api/v1/admin/authentication/login/secure',
        gen,
        this.headers
      )
      .pipe(tap((response: any) => {
        const jwt = this.parseJwt(response['access_token']);
        this.saveKeycloakID(jwt.sub);
        this.saveToken(response['access_token']);
        this.saveRefreshToken(response['refresh_token']);
        this.getLoggedInUser().subscribe((user: Object) => {
          let serializedUser = User.serializeUser(user);
          this.$userState.next(serializedUser);
          this.propagateLogin(serializedUser);

          return response;
        });
      }));
  }

  parseJwt(token: string) {
    try {
      return JSON.parse(window.atob(token.split('.')[1]));
    }
    catch {
      // Do nothing for now
    }
  }

  public logout(): Observable<any> {
    return this.httpClient
      .post<any>('/api/v1/admin/authentication/logout', this.headers)
      .pipe(tap(() => {
        this.$userState.next(null);
        //this.removeToken();
        //this.removeKeycloakId();
        this.removeAllToken();
        this.propagateLogout();
      }));
  }

  public getLoggedInUser(): Observable<User> {
    return this.httpClient.get<User>('/api/v1/admin/user/me');
  }

  public getCurrentUser(): Observable<User> {
    return this.$userState.asObservable();
  }

  public checkUsername(username: string): Observable<any> {
    return this.httpClient.get<User>(`/api/v1/admin/user/username/${username}/exists`);
  }

  public checkUsername2(gen: Generic): Observable<any> {
    return this.httpClient.post<any>(`/api/v1/admin/user/username/exists`, gen);
  }

  public signup(userInfo: any): Observable<any> {
    return this.httpClient.post<any>(
      '/api/v1/admin/user/register',
      userInfo,
      this.headers
    );
  }

  public signup2(gen: Generic): Observable<any> {
    return this.httpClient.post<any>(
      `/api/v1/admin/user/register/secure`,
      gen,
      this.headers
    );
  }

  public forgotPassword(email: string): Observable<any> {
    return this.httpClient.put<any>(`/api/v1/admin/user/password/forgot-password?email=${email}`, {});
  }

  public forgotPassword2(gen: Generic): Observable<any> {
    return this.httpClient.put<any>(`/api/v1/admin/user/password/forgot-password2`, gen);
  }

  public checkResetIdValidity(id: string): Observable<any> {
    return this.httpClient.get<any>(`/api/v1/admin/user/password/reset-password/${id}/valid`);
  }

  public changePassword(details: any): Observable<any> {
    return this.httpClient.put<any>(`/api/v1/admin/user/password/reset-password`, details);
  }

  public changePassword2(gen: Generic): Observable<any> {
    return this.httpClient.put<any>(`/api/v1/admin/user/password/reset-password2`, gen);
  }

  public getToken(): string {
    return sessionStorage.getItem('access_token');
  }

  public getRefreshToken(): string {
    return sessionStorage.getItem('refresh_token');
  }

  public getKeycloakID(): string {
    return sessionStorage.getItem('keycloak_id');
  }

  public saveToken(token: string): void {
    sessionStorage.setItem('access_token', token);
  }

  public saveRefreshToken(token: string): void {
    sessionStorage.setItem('refresh_token', token);
  }

  public saveKeycloakID(id: string): void {
    sessionStorage.setItem('keycloak_id', id);
  }

  public removeToken() {
    sessionStorage.removeItem('access_token');
  }

  public removeKeycloakId() {
    sessionStorage.removeItem('keycloak_id');
  }

  public removeAllToken() {
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('refresh_token');
    sessionStorage.removeItem('keycloak_id');
  }

  public refreshToken(refreshToken: string): Observable<any> {
    let parameters = new HttpParams();
    parameters = parameters.set('refreshToken', refreshToken);

    return this.httpClient
      .get<any>('/api/v1/admin/authentication/refresh', { params: parameters })
      .pipe(tap((response: any) => {
        this.saveToken(response['access_token']);
        this.saveRefreshToken(response['refresh_token']);
        return response;
      }));
  }

  public updateUser(form: any): Observable<any> {
    return this.httpClient
      .put<any>('/api/v1/admin/user', form)
      .pipe(tap((res: any) => {
        this.refreshToken(this.getRefreshToken()).subscribe(() => {
          this.getLoggedInUser().subscribe((user: Object) => {
            this.$userState.next(User.serializeUser(user));
            return res;
          });
        });
      })
    );
  }

  public refreshUser() {
    this.refreshToken(this.getRefreshToken()).subscribe(() => {
      this.getLoggedInUser().subscribe((user: Object) => {
        this.$userState.next(User.serializeUser(user));
      });
    });
  }

  public getRefreshedUser(): Promise<User> {
    return new Promise((resolve, _) => {
      this.refreshToken(this.getRefreshToken()).toPromise().then(() => {
        this.getLoggedInUser().subscribe((user: Object) => {
          let serializedUser = User.serializeUser(user);
          this.$userState.next(serializedUser);
          resolve(serializedUser)
        });
      });
    });
  }

  public updatePassword(input: object): Observable<any> {
    return this.httpClient.put<any>('/api/v1/admin/user/password/change-password', input);
  }

  public updatePassword2(gen: Generic): Observable<any> {
    return this.httpClient.put<any>('/api/v1/admin/user/password/change-password2', gen);
  }

  private propagateSessionMessage(message: any) {
    let iframe = <HTMLIFrameElement>document.getElementById(PROPAGATION_FRAME_ID);
    iframe.contentWindow.postMessage(message, '*');
  }

  private propagateLogout() {
    this.propagateSessionMessage({ logout: true });
  }

  private propagateLogin(user: User) {
    this.propagateSessionMessage({
      access_token: this.getToken(),
      keycloak_id: this.getKeycloakID(),
      email: user.emailAddress
    });
  }

  setSessionItem(key: string, value: string) {
    sessionStorage.setItem(key, value);
  }
  setLocalizeItem(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  getSessionItem(key: string) {
    return sessionStorage.getItem(key);
  }
  getLocalizeItem(key: string) {
    return localStorage.getItem(key);
  }

  removeSessionItem(key: string) {
    sessionStorage.removeItem(key);
  }
  removeLocalizeItem(key: string) {
    localStorage.removeItem(key);
  }

  public validateAgentCode(agentCode: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.get<any>(`api/v1/admin/agent/code/${agentCode}`).subscribe(
        (res: any) => resolve(res),
        (err: any) => reject(err)
      );
    });
  }

  public addAgentLogs(agentInfo: any): Observable<any> {
    return this.httpClient.post<any>(
      'api/v1/admin/agent/addlog',
      agentInfo,
      this.headers
    );
  }
}
