import { Address } from '@insureshop/shared/models/address.model';
import { Name } from '@insureshop/shared/models/name.model';
import { OTP } from '@insureshop/shared/models/otp.model';

export class Payor {
  constructor(
    public name?: Name,
    public emailAddress?: string,
    public mobileNumber?: string,
    public gender?: string,
    public maritalStatus?: string,
    public birthDate?: Date,
    public address?: Address,
    public otp?: OTP,
    public otpVerified?: boolean,
    public consentToDpa?: string,
    public consentToPromos?: string
  ) {}
}
