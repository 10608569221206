import { Component, Inject, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AuthService } from '@insureshop/core/services';

@Component({
    selector: 'app-session-sharing',
    templateUrl: './session-sharing.component.html',
    styleUrls: ['./session-sharing.component.scss']
})
export class SessionSharingComponent implements OnInit  {
    public externalSessionUrl: SafeResourceUrl

    constructor(
        private sanitizer: DomSanitizer,
        private authService: AuthService,
        @Inject('EXTERNAL_SESSION_URL') private configuredExternalSessionUrl: string
    )  {}

    ngOnInit() {
        this.externalSessionUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.configuredExternalSessionUrl);
    }

    @Input() public frameId: string;
}
