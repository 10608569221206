import { AuthService } from '@insureshop/core/services/auth.service';
import { BillingService } from '@insureshop/core/services/billing.service';
import { CmsService } from '@insureshop/core/services/cms.service';
import { InsuranceService } from '@insureshop/core/services/insurance.service';
import { ReferenceService } from '@insureshop/core/services/reference.service';
import { MockDataService } from './mock-data.service';
import { UtilService } from './util.service';
import { PixelService } from '@insureshop/core/services/pixel.service';
import { SocketService } from '@insureshop/core/services/socket.service';
import { AesService } from './aes.service';
import { SessionService } from './session.service';
import { PromoCodeService } from './promo-code.service';
import { ConsoleLoggerService } from './console-logger.service';

export {
  AuthService,
  BillingService,
  CmsService,
  InsuranceService,
  MockDataService,
  ReferenceService,
  UtilService,
  PixelService,
  SocketService,
  AesService,
  SessionService,
  PromoCodeService,
  ConsoleLoggerService
};

export const CoreServices = [
  AuthService,
  BillingService,
  CmsService,
  InsuranceService,
  MockDataService,
  ReferenceService,
  UtilService,
  PixelService,
  SocketService,
  AesService,
  SessionService,
  PromoCodeService,
  ConsoleLoggerService
];
