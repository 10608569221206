import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BillingService, InsuranceService } from '@insureshop/core/services';

@Component({
  selector: 'app-payment-status',
  templateUrl: './payment-status.component.html'
})
export class PaymentStatusComponent implements OnInit {
  constructor(
    private insuranceService: InsuranceService,
    private billingService: BillingService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    if (this.route.snapshot.queryParamMap.has('id')) {
      const idstr = this.route.snapshot.queryParamMap.get('id');
      var id = idstr;
      console.log("***id = " +id);
      console.log("***idstr = " +idstr);
      if(idstr.includes("-d2c")){
        id = idstr.replace('-d2c','');
      }
      console.log("***id = ", id);
      const module = this.route.snapshot.queryParamMap.get('module');
      console.log("***module = ", module);
      let payment: any;

      if (module === 'insurance') {
        console.log("***insurance");
        payment = this.insuranceService.checkPaymentStatus(+id);
      } else if (module === 'billing') {
        console.log("***billing");
        payment = this.billingService.checkPaymentStatus(id);
      } else {
        console.log("***else");
        this.router.navigate(['../failed'], { queryParams: { id: +id }, relativeTo: this.route });
      }

      payment.subscribe((res: any) => {
        if (res.status === 'PAID') {
          this.router.navigate(['../success'], { queryParams: { id: idstr, module: module }, relativeTo: this.route });
        } else if (res.status === 'PENDING') {
          this.router.navigate(['../pending'], { relativeTo: this.route });
        } else {
          this.router.navigate(['../failed'], { queryParams: { id }, relativeTo: this.route });
        }
      });
    } else {
      const referenceNumber = this.route.snapshot.queryParamMap.get('referenceNumber');
      let payment: any
      let id: any

      if(referenceNumber.includes('pl')){
        payment = this.billingService.checkInstallmentStatus(referenceNumber)
      } else {
        payment = this.insuranceService.checkInstallmentStatus(referenceNumber)
      }

      payment.subscribe((res: any) => {
        console.log('server response:', res.id)
        let d2cid = res.id;
        let id = d2cid.toString();
        if(id.includes("-d2c")){
          id = id.replace('-d2c','');
        }
        if (res.status === 'APPROVED') {
          console.log('Success')
          this.router.navigate(['../success'], { queryParams: { referenceNumber: referenceNumber, id: id }, relativeTo: this.route })
        } else {
          this.router.navigate(['../failed'], { queryParams: { referenceNumber, id }, relativeTo: this.route });
        }
      })
    }
  }
}
