import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PromoCodeService {

  triggerComputation: Subject<boolean> = new Subject<boolean>();
  checkPromoCodeValidity:Subject<boolean> = new Subject<boolean>();
  promoCodeApplied: boolean = false;

  constructor() { }

  promoCodeCalculation(calculate: boolean) {
    this.triggerComputation.next(calculate);
  }

  checkPromo() {
    this.checkPromoCodeValidity.next(true);
  }

  getPromoCodeApplied(): boolean {
    return this.promoCodeApplied;
  }

  setPromoCodeApplied(status: boolean): void {
    this.promoCodeApplied = status;
  }
}
