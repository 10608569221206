import { Component, OnInit, Input, Output, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { InsuranceService, ReferenceService } from "@insureshop/core/services";
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-policy-details',
  templateUrl: './policy-details.component.html',
  styleUrls: ['./policy-details.component.scss']
})
export class PolicyDetailsComponent implements OnInit {
  @Input() policyDetail: any;
  @Output() policyCancel: object;
  @Input() expiringSoon: boolean = false;
  @Input() expired: boolean = false;
  @ViewChild('cancelModal') cancelModal: TemplateRef<any>;

  public id: number;
  public insuranceId: number;
  public productName: string;
  public productVarianceName: string;
  public insured: string;
  public policyHolder: string;
  public owner: string;
  public purchaseDate: Date;
  public expiryDate: Date;
  public amount: number;
  public policyNumber: string;
  public receiptNumber: string;
  public epolicyLink: string;
  public covidBurialLink: string;
  public covidIcuLink: string;
  public ctplCocLink: string;

  constructor(
    private router: Router,
    private insuranceService: InsuranceService,
    private referenceService: ReferenceService
  ) {}

  ngOnInit() {
    if (
      this.policyDetail.payor ?
      this.policyDetail.payor.product.name == 'Compulsory Third Party Liability (CTPL) Insurance' : false
    ) {
      this.id = this.policyDetail.id;
      this.productName = 'Compulsory Third Party Liability';
      this.insured =
        this.policyDetail.payor.vehicle.make + ' ' +
        this.policyDetail.payor.vehicle.series + ' ' +
        this.policyDetail.payor.vehicle.plate_number;
      this.owner = this.policyDetail.payor.company_name ?
        this.policyDetail.payor.company_name :
        this.policyDetail.payor.first_name + ' ' + this.policyDetail.payor.last_name;
      this.purchaseDate = this.policyDetail.issue_date;
      this.expiryDate = this.policyDetail.expiry_date;
      this.amount = this.getPriceCtpl(this.policyDetail.payor.vehicle.Pricing);
      this.policyNumber = this.policyDetail.policy_number;
      this.receiptNumber = this.policyDetail.receipt_number;
      this.policyDetail.policy_links.forEach((policyLink: any) => {
        switch(policyLink.product_id) {
          case 'ctpl_coc': this.ctplCocLink = policyLink.link; break;
          default: this.epolicyLink = policyLink.link; break;
        }
      });
    } else {
      if (this.policyDetail && this.policyDetail.productName) {
        const {
          id,
          insuranceId,
          productName,
          productVarianceName,
          insured,
          owner,
          startDate,
          endDate,
          price,
          policyNumber,
          receiptNumber
        } = this.policyDetail;

        this.id = id;
        this.insuranceId = insuranceId;
        this.productName = productName;
        this.productVarianceName = productVarianceName;

        this.insured = insured;
        this.owner = owner ? owner : insured;
        this.purchaseDate = startDate;
        this.expiryDate = endDate;
        this.amount = price;
        this.policyNumber = policyNumber;
        this.receiptNumber = receiptNumber;
      } else {
        this.id = this.policyDetail.id;
        this.productName = this.policyDetail.Product.name;
        this.productVarianceName = this.policyDetail.Product.variant;

        this.insured = this.getName(this.policyDetail.PolicyHolder);
        this.owner = this.getName(this.policyDetail.Payor);
        this.purchaseDate = this.policyDetail.issued_date;
        this.expiryDate = this.policyDetail.expiry_date;
        this.amount = this.getPrice(this.policyDetail.Product);
        this.policyNumber = this.policyDetail.policy_number;
        this.receiptNumber = this.policyDetail.receipt_number;
        this.policyDetail.policy_links.forEach((policyLink: any) => {
          switch(policyLink.product_id) {
            case 'covid_burial': this.covidBurialLink = policyLink.link; break;
            case 'covid_icu': this.covidIcuLink = policyLink.link; break;
            default: this.epolicyLink = policyLink.link; break;
          }
        });
      }
    }
  }

  getName(entry: any) {
    return `${entry.first_name} ${entry.middle_name} ${entry.last_name}`;
  }

  getPrice(product: any) {
    return product.dst + product.fst + product.lgt + product.vat + product.otherCharges + product.premiumPrice;
  }

  getPriceCtpl(product: any) {
    return product.dst + product.dst_on_coc + product.lgt + product.vat + product.auth_fee + product.basic_premium;
  }

  cancel() {
    const data: any = {};

    data.productName = this.productName;
    data.productVarianceName = this.productVarianceName;
    data.policyNumber = this.policyNumber;
    data.id = this.id;

    this.router.navigateByUrl('/user/cancel', { state: data });
  }

  printPolicy(id: number, insuranceId: number) {
    this.insuranceService.getPolicy(id, insuranceId).subscribe(response => {
      const fileName = "policy-" + id + ".pdf";
      saveAs(response, fileName);
    });
  }

  update() {
    if(this.policyDetail.productName === 'SafeTrip') {
      this.router.navigateByUrl('/user/travel-amend', { state: this.policyDetail });
    }
  }

  downloadTermsAndCondition(productName: string, productVarianceName: string) {
    let fileName = '';

    if (productName === 'SafeTrip') {
      fileName = 'SafeTrip - Terms and Conditions.pdf';
    } else if (productName === 'MediCash') {
      fileName = productVarianceName === 'Dengue'
        ? 'MediCash_Dengue_Policy_Contract.pdf'
        : 'MediCash_Leptos_Policy_Contract.pdf';
    } else if (productName === 'RideSure') {
      fileName = 'Ridesure_Policy_Contract.pdf';
    } else if (productName === 'Ooopsie!') {
      fileName = 'Personal_Accident_Insurance_Policy.pdf';
    } else if (productName === 'HospiCash Lite') {
      fileName = 'Accident_and_Health_Insurance_Policy.pdf';
    }

    if(productName !== 'Ooopsie!' && productName !== 'HospiCash Lite') {
      this.referenceService.getFile(fileName).subscribe(
        file => saveAs(file, fileName),
        error => console.log(error)
      );
    }
  }
}
