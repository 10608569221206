import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UtilService } from '@insureshop/core/services';
import { Gender } from '@insureshop/modules/insurance/enums';

@Component({
  selector: 'app-gender-selection',
  templateUrl: './gender-selection.component.html',
  styleUrls: ['./gender-selection.component.scss']
})
export class GenderSelectionComponent implements OnInit {
  @Input() control: FormControl;
  @Input() placeHolderValue: string;

  public options: object;
  public placeHolder = "Select Gender";

  constructor(private util: UtilService) {}

  ngOnInit() {
    if(this.placeHolderValue != undefined){
      this.placeHolder = this.placeHolderValue;
    } 
    this.options = this.util.enumToArray(Gender);
  }
}
