export class Product {
  constructor(
    public id: number,
    public name: string,
    public productName: string,
    public productDetails: {
      id: number,
      scopes: {
        id: number,
        description: string,
        isIncluded: boolean
      } [],
      tableOfCoverages: {
        id: number,
        description: string,
        value: string,
        annualCoverage: string
      } [],
      faqs: {
        id: number,
        question: string,
        answer: string
      }[],
      files: string[],
      productTips: any[]
    },
    public price?: number,
    public initialPrice?: number,
    public isActive?: boolean,
    public healthProductVariancePrice?: {
      premiumTax: number,
      dst: number,
      lgt: number,
      premium: number,
      price: number
    },
    public policyType?: string,
  ) {}
}
