import { Name } from '@insureshop/shared/models/name.model';

export class PrePurchase {
    constructor(
        public name: Name,
        public emailAddress: string,
        public mobileNumber: string,
        public brand: string,
        public year: number,
        public modelCode: string,
        public srp: number,
        public attachments?: any[],
        public id?: number,
        public thisIsMe?: boolean
    ) {}
}
