import { Name } from '@insureshop/shared/models/name.model';

export class HealthMedicashActivation {
  constructor(
    public name: Name,
    public gender: string,
    public birthDate: Date,
    public birthPlace: string,
    public nationality: string,
    public tin: string,
    public sssOrGsis: string,
    public maritalStatus: string,
    public employmentType: string,
    public natureOfWork: string,
    public companyName: string,
    public emailAddress: string,
    public mobileNumber: number,
    public cardNumber: string,
    public pinNumber: string
  ) {}
}
