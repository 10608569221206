import { Component, Input, OnInit } from '@angular/core';
import { Product } from '@insureshop/shared/models';
import { ReferenceService } from "@insureshop/core/services";
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-product-details-modal',
  templateUrl: './product-details-modal.html',
  styleUrls: ['./product-details-modal.component.scss']
})
export class ProductDetailsModalComponent implements OnInit {
  @Input() dismiss;
  @Input() close;
  @Input() product: Product;
  @Input() isDomestic: boolean;

  public modalLogo: String;
  public isAnnual = [];

  constructor(private referenceService: ReferenceService) {}

  ngOnInit() {
    if (this.product.productName === 'SafeTrip') {
      this.modalLogo = 'safetrip-logo.png';
    } else if (this.product.productName === 'MediCash') {
      this.modalLogo = this.product.name === 'Dengue'
        ? 'medicash-dengue-logo.jpg'
        : 'medicash-lepto-logo.jpg';
    } else if (this.product.productName === 'MotorSure') {
      this.modalLogo = 'ridesure-modal-logo.png';
    }

    this.product.policyType === 'ANNUAL' && this.uniqueAnnualCoverages();
  }

  downloadFile(filename: string) {
    this.referenceService.getFile(filename).subscribe(
      file => saveAs(file, filename),
      error => console.log(error)
    );
  }

  productFiles(items: string[]) {
    return this.product.productName === 'MotorSure' ? items.filter(x => x !== 'Terms and Conditions.pdf') : items;
  }

  uniqueAnnualCoverages() {
    const coverages = JSON.parse(JSON.stringify(this.product.productDetails.tableOfCoverages));
    const uniqueCoverages = coverages.reduce((acc: any, current: any) => {
      const x = acc.find((item: any) => item.description === current.description);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

    this.isAnnual = uniqueCoverages;
  }

  coverageValues(description: string, annualCoverage: string) {
    const coverage = this.product.productDetails.tableOfCoverages.find(item =>
      item.description === description && item.annualCoverage === annualCoverage)
    return coverage;
  }
}
