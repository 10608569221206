import { PaymentPendingComponent } from '@insureshop/shared/components/wizard/payment-pending/payment-pending.component';
import { AddressComponent } from './address/address.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { GenderSelectionComponent } from './gender/gender-selection.component';
import { MaritalStatusSelectionComponent } from './marital-status/marital-status-selection.component';
import { NameComponent } from './name/name.component';
import { PaymentStatusComponent } from './payment-status/payment-status.component';
import { PersonalInfoComponent } from './personal-info/personal-info.component';
import { ProductDetailsModalComponent } from './product-details-modal/product-details-modal.component';
import { ProductQuotationsComponent } from './quotations/product-quotations.component';
import { PolicyDetailsComponent } from './policy-details/policy-details.component';
import { UploadInvoiceComponent } from './upload-invoice/upload-invoice.component';
import { PromoCodeComponent } from '@insureshop/shared/components/wizard/promo-code/promo-code.component';

export const Components = [
  AddressComponent,
  DatepickerComponent,
  GenderSelectionComponent,
  MaritalStatusSelectionComponent,
  NameComponent,
  PaymentPendingComponent,
  PaymentStatusComponent,
  PersonalInfoComponent,
  ProductDetailsModalComponent,
  ProductQuotationsComponent,
  PolicyDetailsComponent,
  UploadInvoiceComponent,
  PromoCodeComponent
];

export {
  AddressComponent,
  DatepickerComponent,
  GenderSelectionComponent,
  MaritalStatusSelectionComponent,
  NameComponent,
  PaymentPendingComponent,
  PaymentStatusComponent,
  PersonalInfoComponent,
  ProductDetailsModalComponent,
  ProductQuotationsComponent,
  PolicyDetailsComponent,
  UploadInvoiceComponent,
  PromoCodeComponent
};
