import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

@Injectable()
export class MockDataService {
  constructor(private httpClient: HttpClient) {}

  public getBlocks(): Observable<any> {
    return this.httpClient.get<any>('assets/mock/blocks.json');
  }
}
