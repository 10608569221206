import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';

export const Components = [
  ConfirmationModalComponent
];

export const EntryComponents = [
  ConfirmationModalComponent
];

export {
  ConfirmationModalComponent
};
