export class BillerDetails {
  payMode: string;
  payTerms: string;
  externalId: number;
  pioneerReferenceNumber: string;
  referenceNumber: string;
  description: string;
  policyNumber: string;
  insuredName: string;
  address: string;
  mobileNumber: string;
  emailAddress: string;
  premiumTax: number;
  dst: number;
  lgt: number;
  premium: number;
  price: number;
}
