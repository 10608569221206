import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Injectable()
export class CmsService {
  private headers = { headers: new HttpHeaders().set('exclude', 'true') };

  // Shared data
  private products$: Observable<any>;
  private blocks$: Observable<any>;

  constructor(private httpClient: HttpClient) { }

  // Admin view
  public get(): Observable<any> {
    return this.httpClient.get<any>('/api/v1/cms/block/get', this.headers);
  }

  // Public view
  public getPageBlocks(): Observable<any> {
    if (!this.blocks$) {
      this.blocks$ = this.httpClient
        .get<any>('/api/v1/cms/block/get', this.headers)
        .pipe(shareReplay({ bufferSize: 1, refCount: true }));
    }
    return this.blocks$;
  }

  public update(blockPanels: any): Observable<any> {
    return this.httpClient.put<any>('/api/v1/cms/block/update', blockPanels, this.headers);
  }

  public getHeader(): Observable<any> {
    return this.httpClient.get<any>('/api/v1/cms/header', this.headers);
  }

  public getHero(): Observable<any> {
    return this.httpClient.get<any>('/api/v1/cms/banner', this.headers);
  }

  public getProductOfferings(): Observable<any> {
    if (!this.products$) {
      this.products$ = this.httpClient
        .get<any>('/api/v1/admin/product-category/list', this.headers)
        .pipe(shareReplay({ bufferSize: 2, refCount: true } ));
    }
    return this.products$;
  }

  public getAllBlocks(): Observable<any> {
    return this.httpClient.get<any>('/api/v1/cms/general-content/list', this.headers);
  }

  public getBlock(id: any): Observable<any> {
    return this.httpClient.get<any>(`/api/v1/cms/general-content/get/${id}`, this.headers);
  }

  public saveBlock(block: any): Observable<any> {
    return this.httpClient.post<any>('/api/v1/cms/general-content/create', block, this.headers);
  }

  public updateBlock(block: any): Observable<any> {
    return this.httpClient.put<any>('/api/v1/cms/general-content/update', block, this.headers);
  }

  public removeBlock(id: any): Observable<any> {
    return this.httpClient.delete<any>(`/api/v1/cms/general-content/delete/${id}`, this.headers);
  }
}
