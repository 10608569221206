import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ChangePasswordComponent } from '@insureshop/shared/components/change-password/change-password.component';
import { ConfirmationModalComponent } from '@insureshop/shared/components/modals';
import { LoginComponent } from '@insureshop/shared/components/modals/login/login.component';
import { Components } from '@insureshop/shared/components/wizard';
import { MaterialModule } from '@insureshop/shared/modules/material.module';
import { MobiscrollModule } from '@insureshop/shared/modules/mobiscroll.module';
import { NgBootstrapModule } from '@insureshop/shared/modules/ng-bootstrap.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgBusyModule } from 'ng-busy';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PublicLayoutComponent } from './components/layout/base/public-layout.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { NavBarComponent } from './components/layout/nav-bar/nav-bar.component';
import { TopBarComponent } from './components/layout/top-bar/top-bar.component';
import { ExistingCustomerComponent } from './components/modals/existing-customer/existing-customer.component';
import { OnlineTermsConditionsModalComponent } from './components/modals/online-terms-conditions-modal/online-terms-conditions-modal.component';
import { ResendLinkComponent } from './components/modals/resend-link/resend-link.component';
import { HsbcInstallmentTermsModalComponent } from './components/modals/hsbc-installment-terms-modal/hsbc-installment-terms-modal.component';
import { BpiInstallmentTermsModalComponent } from './components/modals/bpi-installment-terms-modal/bpi-installment-terms-modal.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { SessionExpiredComponent } from './components/expired/session-expired.component';

import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { OtpLimitComponent } from './components/modals/otp-limit/otp-limit.component';
import { InternalErrorComponent } from './components/modals/internal-error/internal-error.component';

@NgModule({
  declarations: [
    PublicLayoutComponent,
    NavBarComponent,
    TopBarComponent,
    FooterComponent,
    ConfirmationModalComponent,
    LoginComponent,
    ChangePasswordComponent,
    ...Components,
    ResendLinkComponent,
    ExistingCustomerComponent,
    OnlineTermsConditionsModalComponent,
    HsbcInstallmentTermsModalComponent,
    BpiInstallmentTermsModalComponent,
    BreadcrumbsComponent,
    SessionExpiredComponent,
    OtpLimitComponent,
    InternalErrorComponent
  ],
  entryComponents: [
    LoginComponent,
    ResendLinkComponent,
    ExistingCustomerComponent,
    OnlineTermsConditionsModalComponent,
    HsbcInstallmentTermsModalComponent,
    BpiInstallmentTermsModalComponent,
    OtpLimitComponent,
    InternalErrorComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    MobiscrollModule,
    NgBootstrapModule,
    NgxDatatableModule,
    NgBusyModule,
    NgSelectModule,
    ReactiveFormsModule,
    RouterModule,
    PdfViewerModule,
    NgZorroAntdModule,
    RecaptchaModule,
    RecaptchaFormsModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    MobiscrollModule,
    NgBootstrapModule,
    NgxDatatableModule,
    NgBusyModule,
    NgSelectModule,
    ReactiveFormsModule,
    RouterModule,
    PublicLayoutComponent,
    FooterComponent,
    ConfirmationModalComponent,
    NgZorroAntdModule,
    ...Components,
    RecaptchaModule,
    RecaptchaFormsModule
  ]
})
export class SharedModule {
}
