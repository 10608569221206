import { Component, Input, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {
  breadcrumbItems: any;
  @Input('items') items: any;
  constructor() { }

  ngOnInit() {
    this.breadcrumbItems = this.items;
  }

  ngOnDestroy() {}
}
