import { HealthPolicyRecipient } from '@insureshop/shared/models/health-policy-recipient.model';
import { HealthPolicyHolder } from '@insureshop/shared/models/health-policy-holder.model';
import { Payor } from '@insureshop/shared/models/payor.model';
import { PriceBreakdown } from '@insureshop/shared/models/price-breakdown.model';
import { Product } from '@insureshop/shared/models/product.model';

export class HealthInsurance {
  constructor(
    public id?: number,
    public productType?: string,
    public recipientType?: string,
    public purchaseType?: string,
    public gift?: boolean,
    public payor?: Payor,
    public product?: Product,
    public productName?: string,
    public productVarianceId?: number,
    public productVarianceName?: string,
    public comprehensiveInsurance?: boolean,
    public healthInsuranceRecipient?: string,
    public policyHolders?: HealthPolicyHolder[],
    public giftRecipients?: HealthPolicyRecipient[],
    public thisIsMe?: boolean,
    public total?: number,
    public priceDetails?: PriceBreakdown,
    public payorValid?: boolean,
    public insuredValid?: boolean,
    public policyHolderCount?: number,
    public giftRecipientCount?: number,
    public enabledTabs?: boolean[],
    public promoCode?: string,
    public discount?: number
  ) {}
}
