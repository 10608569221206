import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BillerDetails, DataTable, PiscBillerDetails, TableParams } from '@insureshop/shared/models';
import { DataTableUtil, FilterParams } from '@insureshop/shared/utils/data-table.util';
import { Observable } from 'rxjs';

@Injectable()
export class BillingService {
  constructor(private httpClient: HttpClient, private dataTableUtil: DataTableUtil) {}

  public getBillerDetails(form: any): Observable<BillerDetails> {
    let params = new HttpParams();
    params = params
      .set('referenceNumber', form.referenceNumber)
      .set('prodType', form.prodType);

    return this.httpClient.get<BillerDetails>('/api/v1/billing/biller/details', { params });
  }

  public getPISCBillerDetails(form: any): Observable<PiscBillerDetails> {
    let params = new HttpParams();
    params = params
      .set('referenceNumber', form.referenceNumber)
      .set('prodType', form.prodType);

    return this.httpClient.get<PiscBillerDetails>(
      `/api/v1/billing/biller/reference-number/${form.referenceNumber}/${form.prodType}/summary-details`
    );
  }

  public payBilling(form: any, paymentType?: string): Observable<any> {
    let params = new HttpParams();
    params = params
      .set('referenceNumber', form.referenceNumber)
      .set('prodType', form.prodType);

    if (paymentType) {
      params = params.set('paymentType', paymentType);
    }
    return this.httpClient.get<any>('/api/v1/billing/biller/payment-request', { params });
  }

  public retryPayBilling(id: any): Observable<any> {
    return this.httpClient.get<any>(`/api/v1/billing/biller/${id}/payment-request`);
  }

  public checkInstallment(form: any, paymentType?: string): Observable<any> {
    let params = new HttpParams();
    params = params
    .set('referenceNumber', form.referenceNumber)
    .set('prodType', form.prodType);

    if (paymentType) {
      params = params.set('paymentType', paymentType)
    }
    return this.httpClient.get<any>('/api/v1/billing/biller/bdo-installment-check', {params})
  }

  public checkHSBCInstallment(form: any, paymentType?: string): Observable<any> {
    let params = new HttpParams();
    params = params
    .set('referenceNumber', form.referenceNumber)
    .set('prodType', form.prodType);

    if (paymentType) {
      params = params.set('paymentType', paymentType)
    }
    return this.httpClient.get<any>('/api/v1/billing/biller/hsbc-installment-check', {params})
  }

  public checkBPIInstallment(form: any, paymentType?: string): Observable<any> {
    let params = new HttpParams();
    params = params
    .set('referenceNumber', form.referenceNumber)
    .set('prodType', form.prodType);

    if (paymentType) {
      params = params.set('paymentType', paymentType)
    }
    return this.httpClient.get<any>('/api/v1/billing/biller/bpi-installment-check', {params})
  }

  public payInstallment(form: any, paymentType?: string): Observable<any> {
    let params = new HttpParams();
    params = params
    .set('referenceNumber', form.referenceNumber)
    .set('prodType', form.prodType);

    if (paymentType) {
      params = params.set('paymentType', paymentType)
    }
    return this.httpClient.get<any>('/api/v1/billing/biller/bdo-installment/payment-request', {params})
  }

  public checkPaymentStatus(id: any): Observable<any> {
    return this.httpClient.get<any>(`/api/v1/billing/payment/status/biller/${id}`);
  }

  public checkInstallmentStatus(referenceNumber: string): Observable<any> {
    return this.httpClient.get<any>(`/api/v1/billing/payment/status/biller/installment/${referenceNumber}`)
  }

  public getOfficialReceipt(shareableLink: string) {
    return this.httpClient.get(
      shareableLink,
      {
        responseType: 'blob',
        headers: { exclude: 'true' }
      }
    );
  }

  public getBlob(shareableLink: string) {
    return this.httpClient.get(
      shareableLink,
      {
        responseType: 'blob',
        headers: { exclude: 'true' }
      }
    );
  }

  public getBillers(tableParams: TableParams, filters?: FilterParams): Observable<DataTable> {
    return this.httpClient.get<DataTable>(
      '/api/v1/billing/biller',
      { params: this.dataTableUtil.buildParams(tableParams, filters) }
    );
  }

  getTransactionData(id: any) {
    return this.httpClient.get<any>(`/api/v1/billing/biller/${id}/transaction-data`);
  }

  getInstallmentTransactionData(referenceNumber: any): Observable<any> {
    return this.httpClient.get<any>(`/api/v1/reference-number/${referenceNumber}/transaction-data`);
  }
}
