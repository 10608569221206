import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Generic} from '@insureshop/shared/models';
import { tap, delay } from 'rxjs/operators';

@Injectable()
export class AesService {

  secretKey = "UGlvbmVlclBTQTIwMjE=1234";
  //secretKey = "";
  sid : string;
  skey : string;

  constructor(private httpClient: HttpClient) { }

  createId() : string{
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < 8; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  generateKey(): Observable<Generic> {
    var id = this.createId();
    var key = '';
    return this.httpClient.post<Generic>(
      `/api/v1/admin/otp/key/${id}`,
      null,
      { headers: { exclude: 'true' } }
    );
  }

  generateAdminKey(): Observable<Generic> {
    var id = this.createId();
    var key = '';
    return this.httpClient.post<Generic>(
      `/api/v1/admin/key/${id}`,
      null,
      { headers: { exclude: 'true' } }
    );
  }

  generateInsuranceKey(): Observable<Generic> {
    var id = this.createId();
    var key = '';
    return this.httpClient.post<Generic>(
      `/api/v1/insurance/key/${id}`,
      null,
      { headers: { exclude: 'true' } }
    );
  }

  encryptString(textToEncrypt: string, gen: Generic) : Generic{
    gen.value = CryptoJS.AES.encrypt(textToEncrypt, gen.value).toString();
    return gen;
  }

  encryptJson(jsonObject: Object, gen: Generic) : Generic{
    const val = JSON.stringify(jsonObject);
    gen.value = CryptoJS.AES.encrypt(val, gen.value).toString();
    return gen;
  }

  decryptToString(textToDecrypt : string): any {
    const deciphered = CryptoJS.AES.decrypt(textToDecrypt, this.secretKey.trim()).toString(CryptoJS.enc.Utf8);
    return deciphered;
  }

  decryptToJson(textToDecrypt : string): any {
    const deciphered = CryptoJS.AES.decrypt(textToDecrypt, this.secretKey.trim()).toString(CryptoJS.enc.Utf8);
    return JSON.parse(deciphered);
  }
}
