import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  constructor() {}

  getAccihealthConfig() {
    return environment.accihealthConfig;
  }

  getCtplConfig() {
    return environment.ctplConfig;
  }

  getMotorCompreConfig() {
    return environment.motorCompreConfig;
  }

  getdefaultEmails() {
    return environment.defautEmails;
  }

  getPetConfig() {
    return environment.petConfig;
  }

  getOfwConfig() {
    return environment.ofwConfig;
  }

  getHomeConfig() {
    return environment.homeConfig;
  }

  getBuhayConfig() {
    return environment.buhayConfig;
  }

  getSureDayTripConfig() {
    return environment.sureDayTripConfig;
  }
}
