import { Component, OnInit, Input, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InsuranceService } from '@insureshop/core/services';

@Component({
  selector: 'app-resend-link',
  templateUrl: './resend-link.component.html',
  styleUrls: ['./resend-link.component.scss']
})
export class ResendLinkComponent implements OnInit {
  @Input() data: any;

  @ViewChild('motorCompreContent') motorCompreContent: TemplateRef<any>;
  isCustom = false; // flag when using custom content
  customContent: any; // content template variable

  constructor(
    private router: Router,
    public activeModal: NgbActiveModal,
    private insuranceService: InsuranceService
  ) {}

  ngOnInit() {
    const currentRoute = this.router.url;
    if(currentRoute.includes('motor-car-comprehensive')) {
      this.isCustom = true;
      this.customContent = this.motorCompreContent;
    }
  }

  resendLink() {
    this.data.source.subscribe(
      () => console.log('Success'),
      (err: any) => console.log('Error: ', err)
    );
  }

  goToHomepage() {
    this.activeModal.close();
    this.router.navigateByUrl('/');
  }
}
