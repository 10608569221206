import { Address } from '@insureshop/shared/models/address.model';
import { Name } from '@insureshop/shared/models/name.model';
import { Payor } from '@insureshop/shared/models/payor.model';
import { PriceBreakdown } from '@insureshop/shared/models/price-breakdown.model';

export class MotorCarWizard {
  triggerCompanyStructure: boolean;
  previousCompanyStructure: string;
  /** preliminary question **/
  carModelDto: {
    id: number,
    variant: string,
    variantV2: string, // key with appended transmission
    brand: string,
    model: string,
    yearId: any,
    transmissionType: string,
    marketValue: number,
    bodyType: string,
    denomination: string,
    seatingCap: number
  };

  hasInsuranceExp: boolean;
  /** preliminary question **/

  /**************************** Start of Registered Owner Information ******************************/

  //INDIVIDUAL
  stgMcIndividualDto: {
    stgMcIndividualId: number,
    personDetail: {
      firstName: any,
      middleName: any,
      lastName: any,
      suffix: any,
      email: any,
      contactNo: any,
      birthDate: any,
      sexAtBirth: any,
      civilStatus: any,
      tinNumber: any,
      pob: any, //place of birth?
      nationality: any,
      nationalityName: any,
      isHighRiskPob: any,
      isHighRiskNationality: any
    };
    address: {
      province: string,
      city: string,
      barangay: string,
      streetAddress: string,
    };
    secondaryAddress: {
      province: string,
      city: string,
      barangay: string,
      streetAddress: string,
    };
  }

  //ORGANIZATION
  stgMcBusinessDto: {
    stgMcBusinessId: 0,
    company: {
      companyName: any,
      companyStructure: any,
      companyMobileNo: any,
      companyTelephoneNo: any,
      companyEmail: any,
      companyTin: any,
      businessStyle: any,
      address: {
        province: any,
        city: any,
        barangay: any,
        streetAddress: any,
      },
      secondaryAddress: {
        province: any,
        city: any,
        barangay: any,
        streetAddress: any,
      }
    },
    representativeDetail: {
      firstName: any,
      middleName: any,
      lastName: any,
      suffix: any,
      email: any,
      contactNo: any,
      position: any,
    },
  };

  /****************************** END of Registered Owner Information ******************************/

  /****************************** Eligibility ******************************/

  //INDIVIDUAL
  stgMcElIndividualDto: {
    stgMcElIndividualId: number,
    incomeSource: string,
    incomeSourceOther: string,

    //salary
    employerBusinessName: string,
    workBusinessNature: string,
    workBusinessNatureOther: string,

    //business
    businessName: string,
    businessWorkNature: string,
    businessWorkNatureOther: string,

    //commision
    commissionWorkNature: string,
    commissionWorkNatureOther: string,

    idType: string,
    idExpiryDate: any,
    pepIndividual: {
      pepOfficial: boolean,
      pepCandidate: boolean,
      pepRelative: boolean,
      pepRelativeCandidate: boolean
    }
  };

  //ORGANIZATION
  stgMcElBusinessDto: {
    stgMcElBusinessId: number,
    incomeSource: string,
    incomeSourceOther: string,
    workBusinessNature: string,
    workBusinessNatureOther: string,
    idType: string,
    idExpiryDate: string,
    businessVerification: {
      generalInfoSheet: string,
      articlesOfPartnership: string,
      articlesOfCooperation: string,
      certOfRegistration: string
    },
    pepBusiness: {
      pepOrgOfficial: boolean,
      pepOrgRelativeOfficial: boolean
    }
  };
  /****************************** Eligibility ******************************/


  // Coverage
  deductibleDiscountId: number // quotation discount od/t ID
  noClaimBonusDiscountId: number // quotation discount basic prem ID

  quotationDiscountsDto: [
    {
      quotationDiscountId: number,
      name: string,
      rate: number,
      amount: number,
      code: any,
      subjectForUpdate: boolean
    }
  ]
  stgMcQuotationDto: {
    stgMcQuotationId: number,
    personPartialInfo: {

    },
    prelimQuestions: {
      insuredType: string,
      usageType: string,
      existingVehicleInsurance: boolean,
      insuranceClaim: string,
      mortgagee: string,
      othersMortgagee: string,
    };
    address: {
      province: string,
      city: string,
      barangay: string
      streetAddress: string
    };
    includeCtpl: boolean,
    agentCode: string,
    promoCode: string,
    quotation: {
      coverageDetail: {
        odAmt: number,
        theftAmt: number,
        aonAmt: number,
        aonFlag: string,
        withAutoRescue: boolean,
        ppaAmt: number,
        ppaPerSeatAmt: number,
        seatCovered: number,
        ppaMedAmt: number,
        tpbiAmt: number,
        tppdAmt: number
      },
      premiumDetail: {
        basicPremium: number,
        totalPremium: number,
        odPrem: number,
        theftPrem: number,
        autoRescuePrem: number,
        tppdPrem: number,
        tpbiPrem: number,
        ppaPrem: number,
        ppaPerSeatPrem: number,
        aonPrem: number,
        tax: {
          dst: number,
          lgt: number,
          vat: number
        }
      },
      deductible: number,
      deductibleRate: number,
      fmv: number,
      matchMyCoverageSupportingDocument: string;
    },
    savedSession: string,
    draft: boolean,
    isMatchMyCoverage: boolean
  };

  ctplDto: {
    stgCtplId: number,
    ctplPolicyNo: string,
    ctplInceptionDate: string,
    ctplExpirationDate: string,
    ctplSeries: string,
    authenticationCode: string,
    premiumType: string,
    cocNumber: string,
    tax: {
      dst: number,
      lgt: number,
      vat: number
    },
    authenticationFee: number,
    basicPremium: number,
    totalPremium: number,
    coverage: number
  };

  dataPrivacyDto: {
    dataPrivacyId: number,
    dataPrivacyConsentTag: boolean,
    dataPrivacyConsentTimestamp: string,
    marketingConsentTag: boolean,
    marketingConsentTimestamp: string,
    termsConditionConsentTag: boolean,
    termsConditionConsentTimestamp: string,
  };


  // VEHICLE INFO

  stgMcVehicleDto: {
    stgMcVehicleId: number,
    vehicleDetail: {
      color: string,
      denomination: string,
      brand: string,
      model: string,
      bodyType: string,
      yearModel: number,
      variant: string,
      transmission: string,
      seatingCap: number,
      value: number,
      mvFileNo: string,
      plateNo: string,
      csNo: string,
      engineNo: string,
      chassisNo: string
    },
    mortgageName: string,
    othersMortgageName : string,
    effDate: Date,
    expiryDate: Date,
    attachmentWrapper: {
      imagePerspectiveAttachment: {
        rightSide: string,
        leftSide: string,
        frontSide: string,
        backSide: string
      },
      documentAttachment: {
        /*salesInvoice: string,
        deedOfTransfer: string,
        others: string,
        othersDescription: string,*/
        officialReceipt: string,
        certificateOfRegistration: string
      },
      validId: string//,
      //selfieWithValidId: string,
    },
    ctplData: {
      ctplCover: string,
      effDate: any,
      expiryDate: any,
      series: string;
    },
  }

  uploadReminderFlag: boolean;
  effDateAdjustmentFlag: boolean;
  engineChassisValidated: boolean;
  draftEngineChassisValidate: boolean;


  attachments: any[];
  businessVerificationAttachments: any[];
  promoDiscounts: any;
  promoCodeRecompute: boolean;

  // Personal Info
  payor: Payor;
  payorValid: boolean;

  policyHolderIsPayor: boolean;

  // Driver and Policy Holder
  policyHolder: {
    name: Name;
    gender: string;
    maritalStatus: string;
    birthDate: Date;
    address: Address;
    emailAddress: string;
    mobileNumber: number;
  };

  policyHolderIsDriver: boolean;

  driver: {
    name: Name;
    gender: string;
    maritalStatus: string;
    birthDate: Date;
    licenseNumber: string;
  };

  chassisNumber: string;
  engineNumber: string;
  plateNumber: string;

  motorInsuranceImages: any[];
  invoice: any[];

  id: number;

  enabledTabs: boolean[];

  promoCode?: string;
  discount?: number;

  targetStepFromReviewPage: any;
  scrolltobottom: any;
}
