import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService, AesService } from "@insureshop/core/services";
import { FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective, NgForm } from "@angular/forms";
import { PASSWORD } from "@insureshop/shared/constants/regex-patterns";
import { User } from '@insureshop/shared/models/user.model';
import { Generic } from '@insureshop/shared/models';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { ErrorStateMatcher } from '@angular/material/core';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(
      control &&
      control.parent &&
      control.parent.invalid &&
      control.parent.dirty
    );

    return invalidCtrl || invalidParent;
  }
}

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  public expired: boolean = false;
  public resetting: boolean = false;
  public failed: boolean = false;
  public success: boolean = false;

  public resetPasswordForm: FormGroup;
  public resetId: string = '';
  public userId: string = '';
  public resetCode: string = '';
  public changePasswordError: string = '';
  public matcher = new MyErrorStateMatcher();

  public showPassword: boolean = false;
  public showPasswordConf: boolean = false;
  public gen: Generic;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private fb: FormBuilder,
    private aes: AesService
  ) {}

  ngOnInit() {
    this.authService.getCurrentUser().pipe(untilComponentDestroyed(this)).subscribe((user: User) => {
      if(user) {
        this.router.navigateByUrl('/');
      }
    });

    this.activatedRoute.params.subscribe(params => {
      this.userId = params.userId;
      this.resetId = params.id;
      this.resetCode = params.resetCode;

      // this.resetPasswordForm = this.fb.group({
      //   password: [null, [Validators.required, Validators.pattern(PASSWORD)]],
      //   confirmPassword: [null, [Validators.required, Validators.pattern(PASSWORD)]]
      // });
      this.resetPasswordForm = this.fb.group({
          password: [null, [Validators.required, Validators.pattern(PASSWORD)]],
          confirmPassword: [null, [Validators.required, Validators.pattern(PASSWORD)]]
        },
        { validators: this.matchPassword }
      );

      // check if link is valid
      this.authService.checkResetIdValidity(params.id).subscribe(response => {
        if (!response.valid) {
          this.expired = true;
        }
      }, () => {
      });
    });
  }

  matchPassword(group: FormGroup) {
    let pass = group.controls.password.value;
    let confirmPass = group.controls.confirmPassword.value;

    return pass === confirmPass ? null : { notSame: true };
  }

  changePassword() {
    this.failed = false;
    this.resetting = true;
    let resetPasswordDetails = {
      id: this.resetId,
      userId: this.userId,
      resetCode: this.resetCode,
      password: this.password.value
    };

    this.aes.generateAdminKey().subscribe((gen: Generic) => {
      this.gen = this.aes.encryptJson(resetPasswordDetails, gen);
      this.authService.changePassword2(this.gen).subscribe(() => {
        this.resetting = false;
        this.success = true;
      }, error => {
        this.changePasswordError = error.error.errorMessage || error.error.error_description;
        this.resetting = false;
        this.failed = true;
      });
    });
  }

  togglePasswordMask(passwordRef: string) {
    switch(passwordRef) {
      case 'password':
        this.showPassword = !this.showPassword;
        break;

      default:
        this.showPasswordConf = !this.showPasswordConf;
    }
  }

  get password() { return this.resetPasswordForm.get('password'); }
  get confirmPassword() { return this.resetPasswordForm.get('confirmPassword'); }

  ngOnDestroy() {}
}
