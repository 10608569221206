/**
 * Accepted Pattern
 * xxxxxxxxxxx - 11 digits
 * xxx/x xxxxxxx - 3 or 4 digits <space> 7 digits
 * xxx/x xxxxxxx loc xxx/x - 3 or 4 digits area code <space> 7 digits <space> 'loc' <space> 3 or 4 digits
 */
export const CONTACT_NUMBER_FORMAT = /^\d{10}$/;
export const EMAIL_FORMAT = /^[A-Za-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/i;
export const MULTIPLE_EMAIL_FORMAT = /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/;
export const NAME_FORMAT = /^[ña-z ,.'-]+$/i;
export const NUMBERS_ONLY = '^[0-9]*$'; // ^[0-9]+(\.[0-9]{1,2})?$
//export const PASSWORD = /^(?=.*[A-Z])(?=.*\W)[A-Za-z\d\W]{8,}$/;
export const PASSWORD = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=])(?=\S+$).{8,}$/;
export const DRIVER_LICENSE_FORMAT = /^([A-Z]\d{2}-\d{2}-[0-9]*$)/;
export const PROMO_CODE_FORMAT = /^[a-zA-Z0-9%_&=+-><*/.]{1,20}$/; // alphanumeric w/ some special chars
