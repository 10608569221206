import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { HsbcInstallmentTerm } from '@insureshop/modules/insurance/enums';
import { UtilService } from '@insureshop/core/services';

@Component({
  selector: 'app-hsbc-installment-terms-modal',
  templateUrl: './hsbc-installment-terms-modal.component.html',
  styleUrls: ['./hsbc-installment-terms-modal.component.scss']
})
export class HsbcInstallmentTermsModalComponent implements OnInit {
  public formGroup: FormGroup;
  @Input() next: any;
  @Input() term: any;
  @Input() availableTerms: any;
  @Input() control: FormControl;

  public options: object;

  constructor(public activeModal: NgbActiveModal, private fb: FormBuilder, private util: UtilService) { }

  ngOnInit() {
    //console.log('availableTerms');
    //console.log(this.availableTerms);
    var allterms_array = this.availableTerms.split(',');
    let filteredterms_array = [];
    for(var i = 0; i < allterms_array.length; i++) {
      // Trim the excess whitespace.
      allterms_array[i] = allterms_array[i].replace(/^\s*/, "").replace(/\s*$/, "");
      filteredterms_array.push(this.getHsbcEnumObject(allterms_array[i]));
    }
    //console.log(myArray);
    this.options = filteredterms_array;
    this.buildForm();
  }

  buildForm() {
    this.formGroup = this.fb.group({
      hsbcInstallmentTerm: [null, Validators.required],
    });
  }

  get hsbcInstallmentTerm() {
    return this.formGroup.get('hsbcInstallmentTerm');
  }

  proceed() {
    //console.log(this.formGroup.get('hsbcInstallmentTerm').value);
    this.term = this.formGroup.get('hsbcInstallmentTerm').value;
    this.activeModal.close(this.term)
  }

  getHsbcEnumObject(term: string){
    switch(term) {
      case '3': return { value: 'HSBC_INSTALLMENT_3M', label: 'HSBC Installment 3M' };
      case '6': return { value: 'HSBC_INSTALLMENT_6M', label: 'HSBC Installment 6M' };
      case '9': return { value: 'HSBC_INSTALLMENT_9M', label: 'HSBC Installment 9M' };
      case '12': return { value: 'HSBC_INSTALLMENT_12M', label: 'HSBC Installment 12M' };
      case '18': return { value: 'HSBC_INSTALLMENT_18M', label: 'HSBC Installment 18M' };
      case '24': return { value: 'HSBC_INSTALLMENT_24M', label: 'HSBC Installment 24M' };
      case '36': return { value: 'HSBC_INSTALLMENT_36M', label: 'HSBC Installment 36M' };
   }
  }
}
