export * from './address.model';
export * from './data-table.model';
export * from './name.model';
export * from './payor.model';
export * from './travel-insurance.model';
export * from './travel-wizard.model';
export * from './health-policy-recipient.model';
export * from './health-insurance.model';
export * from './health-medicash-activation';
export * from './health-policy-holder.model';
export * from './epayment.model';
export * from './product.model';
export * from './otp.model';
export * from './price-breakdown.model';
export * from './motor-wizard.model';
export * from './motorcar-wizard.model';
export * from './motor-insurance.model';
export * from './rate-card.model';
export * from './pre-purchase.model';
export * from './motor-existing-customer.model';
export * from './biller-details.model';
export * from './policy-detail.model';
export * from './pisc-biller-details.model';
export * from './generic.model';
export * from './pre-purchase-car';
export * from './pre-purchase-mortgagee';
