export * from './purchase-type.enum';
export * from './channel-source.enum';
export * from './employment-type.enum';
export * from './gender.enum';
export * from './health-product-variance.enum';
export * from './insurance-recipient.enum';
export * from './marital-status.enum';
export * from './updated-marital-status.enum';
export * from './travel-companion.enum';
export * from './travel-purpose.enum';
export * from './recipient.enum';
export * from './suffix.enum';
export * from './payment-method.enum';
export * from './hsbc-installment-term.enum';

