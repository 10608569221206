import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable()
export class ReferenceService {
  private headers = new HttpHeaders().set('exclude', 'true');

  constructor(
    private httpClient: HttpClient) {}

  public getDestinations(): Observable<any> {
    return this.httpClient.get<any>(
      `/api/v1/admin/destination/available`,
      { headers: this.headers }
    );
  }

  public getDestinationsByType(parameters: any): Observable<any[]> {
    let params = new HttpParams();
    params = params.set('destinationType', parameters.destinationType);

    if (parameters.alias) {
      params = params.set('alias', parameters.alias);
    }
    return this.httpClient.get<any[]>(`/api/v1/admin/destination/type`, { params });
  }

  public getTravelQuotations(prelim: any): Observable<any[]> {
    let parameters = new HttpParams();
    parameters = parameters.set('ids', prelim.destinationIds.join(','));
    parameters = parameters.set('days', prelim.days);
    parameters = parameters.set('travelCompanion', prelim.travelCompanion);
      // TravelCompanion[prelim.travelCompanion] ===
      //   TravelCompanion.FAMILY_NO_MINOR
      //   ? (TravelCompanion.GROUP as string).toUpperCase()
      //   : prelim.travelCompanion

    return this.httpClient.get<any[]>(
      `/api/v1/admin/travel-product-variance/destinations`,
      {
        params: parameters,
        headers: this.headers
      }
    );
  }

  public getTravelQuotationsByRank(prelim: any): Observable<any[]> {
    let parameters = new HttpParams();
    parameters = parameters.set('ids', prelim.destinationIds.join(','));
    parameters = parameters.set('days', prelim.days);
    parameters = parameters.set('travelCompanion', prelim.travelCompanion);

    return this.httpClient.get<any[]>(
      `/api/v1/admin/travel-product-variance/destinations/v1`,
      {
        params: parameters,
        headers: this.headers
      }
    );
  }

  public getTravelQuotationsWCovid(prelim: any): Observable<any[]> {
    let parameters = new HttpParams();
    parameters = parameters.set('ids', prelim.destinationIds.join(','));
    parameters = parameters.set('days', prelim.days);
    parameters = parameters.set('travelCompanion', prelim.travelCompanion);
    parameters = parameters.set('policyType', 'COVID');

    return this.httpClient.get<any[]>(
      `/api/v1/admin/travel-product-variance/destinations/v2`,
      {
        params: parameters,
        headers: this.headers
      }
    );
  }

  public getAnnualTravelPlans(destinationIds: number[]): Observable<any[]> {
    let parameters = new HttpParams();
    parameters = parameters.set('ids', destinationIds.join(','));

    return this.httpClient.get<any[]>(
      `/api/v1/admin/travel-product-variance/policy-type/annual`,
      {
        params: parameters,
        headers: this.headers
      }
    );
  }

  public getHealthQuotations(type: string): Observable<any[]> {
    return this.httpClient.get<any[]>(
      `/api/v1/admin/health-product-variance/health-product-variance-type/${type}`,
      { headers: this.headers }
    );
  }

  public getProvinces(): Observable<any> {
    return this.httpClient.get<any>(
      `/api/v1/admin/address/province/list`,
      { headers: this.headers }
    );
  }

  getProvincesLOV(): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.motorCompreConfig.url}/hodu/service-lov/v1.0/address/region`
    );
  }

  public getCities(id: string): Observable<any> {
    let parameters = new HttpParams();
    parameters = parameters.set('id', id);

    return this.httpClient.get<any>(
      `/api/v1/admin/address/city/list`,
      {
        params: parameters,
        headers: this.headers
      }
    );
  }

  getCitiesLOV(island: string, province: string): Observable<any> {
    let parameters = new HttpParams();
    parameters = parameters
      .set('island', island)
      .set('region', province);
    return this.httpClient.get<any>(
      `${environment.motorCompreConfig.url}/hodu/service-lov/v1.0/address/municipality`,
      {
        params: parameters,
      }
    );
  }

  public getBarangays(id: string): Observable<any> {
    let parameters = new HttpParams();
    parameters = parameters.set('id', id);

    return this.httpClient.get<any>(
      `/api/v1/admin/address/barangay/list`,
      {
        params: parameters,
        headers: this.headers
      }
    );
  }

  getBarangayLOV(province: string, city: string): Observable<any> {
    let parameters = new HttpParams();
    parameters = parameters
      .set('region', province)
      .set('municipality', city);

    return this.httpClient.get<any>(
      `${environment.motorCompreConfig.url}/hodu/service-lov/v1.0/address/brgy`,
      {
        params: parameters,
      }
    );
  }

  public getZipCodes(city: string, province: string): Observable<any> {
    let parameters = new HttpParams();
    parameters = parameters
      .set('city', city)
      .set('province', province);

    return this.httpClient.get<any>(
      `/api/v1/admin/address/zipcode/list`,
      {
        params: parameters,
        headers: this.headers
      }
    );
  }

  public getIdTypes(): Observable<any> {
    return this.httpClient.get<any>(
      `/api/v1/insurance/id-type/list`,
      { headers: this.headers }
    );
  }

  public getFile(filename: string): Observable<Blob> {
    return this.httpClient.get(
      `/api/v1/admin/file/download/${filename}`,
      {
        responseType: 'blob',
        headers: { exclude: 'true' }
      }
    );
  }

  public getMotorcycleBrands(): Observable<any> {
    return this.httpClient.get<any>(
      `/api/v1/admin/motor/brand`,
      { headers: this.headers }
    );
  }

  getCarBrands(): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.motorCompreConfig.url}/hodu/service-lov/v1.0/car/brand`,
    );
  }

  public getMotorcycleYears(brandId: number): Observable<any> {
    return this.httpClient.get<any>(
      `/api/v1/admin/motor/brand/${brandId}/year`,
      { headers: this.headers }
    );
  }

  public getMotorcycleModels(brandId: number, yearId: number): Observable<any> {
    return this.httpClient.get<any>(
      `/api/v1/admin/motor/brand/${brandId}/year/${yearId}/model`,
      { headers: this.headers }
    );
  }

  getCarModels(year: string, brand: string): Observable<any> {
    let parameters = new HttpParams();
    parameters = parameters
      .set('year', year)
      .set('brand', brand);
    return this.httpClient.get<any>(
      `${environment.motorCompreConfig.url}/hodu/service-lov/v1.0/car`,
      {
        params: parameters
      }
    );
  }
  getCarVariants(year: string, brand: string, model: string): Observable<any> {
    let parameters = new HttpParams();
    parameters = parameters
      .set('year', year)
      .set('brand', brand)
      .set('model', model);
    return this.httpClient.get<any>(
      `${environment.motorCompreConfig.url}/hodu/service-lov/v1.0/car`,
      {
        params: parameters
      }
    );
  }

  getCarDenomination(): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.motorCompreConfig.url}/hodu/service-lov/v1.0/others/denomination`
    );
  }

  getMortgagee(): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.motorCompreConfig.url}/hodu/service-lov/v1.0/others/mortgagee`
    );
  }

  getSuffix(): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.motorCompreConfig.url}/hodu/service-lov/v1.0/others/suffix`
    );
  }

  public getMotorProductVariance(id: number, price: number): Observable<any> {
    return this.httpClient.get<any>(`/api/v1/admin/motor-product-variance/price?id=${id}&price=${price}`);
  }

  public getTravelProductVariances(): Observable<any> {
    return this.httpClient.get<any>(`/api/v1/admin/travel-product-variance`);
  }

  getDenominationLOV(): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.motorCompreConfig.url}/hodu/service-lov/v1.0/others/denomination`
    );
  }

  getValidIdLOV(): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.motorCompreConfig.url}/hodu/service-lov/v1.0/others/validid`
    );
  }

  getNatureOfWorkLOV(): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.motorCompreConfig.url}/hodu/service-lov/v1.0/others/natureofbusiness`);
  }

  getNationalities(): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.motorCompreConfig.url}/hodu/service-lov/v1.0/others/nationalities`
    );
  }

  getCountries(): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.motorCompreConfig.url}/hodu/service-lov/v1.0/address/countries`
    );
  }
}
