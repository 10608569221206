import { environment } from '@env/environment';
import { IdleService } from './core/services/idle-service';
import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { PROPAGATION_FRAME_ID } from './shared/constants/session-propagation-constants';
import { AuthService, ConsoleLoggerService } from './core/services';

declare var gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'InsureShop';
  public sessionPropagationFrameId: string
  agentcode = null;
  constructor(
    public router: Router,
    private ccService: NgcCookieConsentService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private idleService: IdleService,
    private consoleLoggerService: ConsoleLoggerService
  ) {
    this.sessionPropagationFrameId = PROPAGATION_FRAME_ID
    this.consoleLoggerService.disableConsoleLogsProd();
  }

  ngOnInit() {
    this.agentcode =  this.authService.getSessionItem('agent_code');
    console.log('agentcode from db', this.agentcode);
    this.validateAgentCode();
    this.initialIdleSettings();
  }

  validateAgentCode() {
    this.route.queryParams.subscribe((params: any) => {
      if (params['agentcode']) {
        this.authService.validateAgentCode(params['agentcode']).then(data => {
          if (data) {
            if (data['sessionTag'] === "SS"){
              this.authService.setSessionItem('agent_code', data['agentcode']);
              this.authService.setSessionItem('promo_code', data['promocode']);
              this.authService.setSessionItem('hash_key', params['agentcode']);
              this.authService.setSessionItem('url', window.location.href);
              this.authService.setSessionItem('session_tag', data['sessionTag']);
              this.authService.setSessionItem('branch', data['branch']);
              if(data['promocode'].includes('EMP')){
                console.log('employee name' + data['firstName'] + ' ' + data['lastName']);
                this.authService.setSessionItem('first_name', data['firstName']);
                this.authService.setSessionItem('last_name', data['lastName']);
              }
              this.agentcode =  this.authService.getSessionItem('agent_code');
              console.log('new session agentcode', this.agentcode);
              this.authService.removeLocalizeItem('agent_code');
              this.authService.removeLocalizeItem('promo_code');
              this.authService.removeLocalizeItem('hash_key');
              this.authService.removeLocalizeItem('url');
              this.authService.removeLocalizeItem('session_tag');
              this.authService.removeLocalizeItem('branch');
            }else{
              this.authService.setLocalizeItem('agent_code', data['agentcode']);
              this.authService.setLocalizeItem('promo_code', data['promocode']);
              this.authService.setLocalizeItem('hash_key', params['agentcode']);
              this.authService.setLocalizeItem('url', window.location.href);
              this.authService.setLocalizeItem('session_tag', data['sessionTag']);
              this.authService.setLocalizeItem('branch', data['branch']);
              this.agentcode =  this.authService.getLocalizeItem('agent_code');
              console.log('new local agentcode', this.agentcode);
              this.authService.removeSessionItem('agent_code');
              this.authService.removeSessionItem('promo_code');
              this.authService.removeSessionItem('hash_key');
              this.authService.removeSessionItem('url');
              this.authService.removeSessionItem('session_tag');
              this.authService.removeSessionItem('branch');
              this.authService.removeSessionItem('first_name');
              this.authService.removeSessionItem('last_name');
            }
          }
          // else {
          //   this.authService.removeSessionItem('agent_code');
          //   this.authService.removeSessionItem('promo_code');
          //   this.authService.removeSessionItem('hash_key');
          //   this.authService.removeSessionItem('url');
          // }
        }, () => {
          console.error();
          this.authService.removeSessionItem('agent_code');
          this.authService.removeSessionItem('promo_code');
          this.authService.removeSessionItem('hash_key');
          this.authService.removeSessionItem('url');
        });
      }
    });
  }

  private initialIdleSettings() {
    const idleTimeoutInSeconds: number = environment.idleTimeInMinutes * 60;
    this.idleService.startWatching(idleTimeoutInSeconds).subscribe((isTimeOut: boolean) => {
      if (isTimeOut && this.authService.getKeycloakID() != null) {
        alert("You have been logged out due to inactivity.");
        this.authService.logout().subscribe(
          () => true,
          () => {
            this.authService.$userState.next(null);
            this.authService.removeToken();
            this.authService.removeKeycloakId();
          }
        );
      }
    });
  }

}
