import { Name } from '@insureshop/shared/models/name.model';

export class PrePurchaseMortgagee {
    constructor(
        public name: Name,
        public emailAddress: string,
        public contactNumber: string,
        // public brand: string,
        // public yearModel: string,
        // public model: string,
        // public variant: string,
        // public transmission: string,
        // public bodyType: string,
        // public denomination: string,
        // public seatingCap: number,
        // public value: string,
        public mortgageName: string,
        public supportDoc: string,
        public attachments?: any[],
        public id?: number,
        public thisIsMe?: boolean
    ) {}
}
